import { useCallback } from 'react'
import ApiService from 'services/ApiService'
import { getMessage } from 'utility/MapperResponse'
import { getTranslate } from 'utility/MapperTranslate'
import { showAlert } from 'utility/SweetAlert'

const InteractorProductPriceDiscountUpdateBatch = () => {
  const priceDiscountUpdateBatch = useCallback((body, loading, path = '', reload = false) => {
    let payload = {
      ...body,
    }
    if (body.isArray) {
      payload = [...body.data]
    }
    const isAlert = body.alert ?? false
    ApiService.jsonRequest('/product/price-discount/update-batch', payload, (response) => {
      loading(false)
      if (response.code === 7200) {
        if (isAlert) {
          showAlert({
            icon: 'success',
            message:
              payload.is_deleted === 'Y'
                ? getTranslate('message.successfully_deleted')
                : getTranslate('message.successfully_updated'),
            timer: 2000,
            redirect: reload ? '' : `/product/discount/manage${path}`,
            reload,
          })
        }
      } else {
        showAlert({
          icon: 'error',
          message: getMessage(response?.msg),
          timer: 2000,
        })
      }
    })
  }, [])
  return { priceDiscountUpdateBatch }
}

export default InteractorProductPriceDiscountUpdateBatch
