import React from 'react'
import { Menu, Dropdown, Avatar } from 'antd'
import { connect } from 'react-redux'
import { EditOutlined, KeyOutlined, LogoutOutlined } from '@ant-design/icons'
import Icon from 'components/utils/icon'
import { removeItem } from 'utility/Session'
import { useSelector } from 'react-redux'
import { getTranslate } from 'utility/MapperTranslate'
import { valueFormatter } from 'utility/Helpers'
import { StyleNavigation } from 'components/style/StyleComponent'

const menuItem = [
  {
    title: getTranslate('menu.profile'),
    icon: EditOutlined,
    path: '/account/profile',
  },

  {
    title: getTranslate('menu.change_password'),
    icon: KeyOutlined,
    path: '/account/change-password',
  },
]

export const NavProfile = () => {
  const { account } = useSelector((state) => state.account)

  const SignOut = () => {
    removeItem('wdg-access')
    removeItem('wdg-account')
    removeItem('wdg-menu')
    removeItem('wdg-notification-unread')
    window.location.reload(false)
  }

  const profileImg =
    account.account_profile_picture !== ''
      ? account.account_profile_picture
      : require('../../assets/img/no-image.png')
  const profileMenu = (
    <StyleNavigation>
      <div className="nav-profile nav-dropdown">
        <div className="nav-profile-header">
          <div className="d-flex">
            <Avatar size={45} src={profileImg} />
            <div className="pl-3">
              <h4 className="mb-0">{account.account_full_name}</h4>
              {account.account_role !== 'CMS' ? (
                <span className="text-muted">
                  {valueFormatter(
                    'options:employee_position_type',
                    account.account_information.employee_position_type,
                  )}
                </span>
              ) : (
                <span className="text-muted">{account.account_role}</span>
              )}
            </div>
          </div>
        </div>
        <div className="nav-profile-body">
          <Menu>
            {menuItem.map((el, i) => {
              return (
                <Menu.Item key={i}>
                  <a href={el.path}>
                    <Icon type={el.icon} />
                    <span className="font-weight-normal">{el.title}</span>
                  </a>
                </Menu.Item>
              )
            })}
            <Menu.Item key={menuItem.length + 1} onClick={SignOut}>
              <span className="nav-text">
                <LogoutOutlined className="nav-text" />
                <span className="font-weight-normal nav-text">{getTranslate('menu.signout')}</span>
              </span>
            </Menu.Item>
          </Menu>
        </div>
      </div>
    </StyleNavigation>
  )
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={['click']}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar src={profileImg} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  )
}

export default connect(null)(NavProfile)
