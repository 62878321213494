import { InteractorCorporateOrganizationSearch } from 'interactors/Main'
import { useEffect, useState } from 'react'
import { mappingList } from 'utility/MapperData'
import { getTranslate } from 'utility/MapperTranslate'

export const FilterCorporate = () => {
  const { corporateOrganizationSearch } = InteractorCorporateOrganizationSearch()
  const [loading, setLoading] = useState(true)
  const [listOrganization, setlistOrganization] = useState([])

  useEffect(() => {
    if (loading && process.env.REACT_APP_APPLICATION === 'WEB_CMS') {
      corporateOrganizationSearch(
        {
          output: 'list',
          keywords: {
            status: 'ACTIVE',
          },
        },
        setlistOrganization,
        setLoading,
      )
    } else {
      setLoading(false)
    }
  }, [corporateOrganizationSearch, loading])

  let corporate = null
  if (process.env.REACT_APP_APPLICATION === 'WEB_CMS') {
    corporate = {
      filter_target: 'select',
      initial_field: 'corporate_uid',
      placeholder: getTranslate('title.please_select|field.corporate_name'),
      required: false,
      items: mappingList(listOrganization, ['uid', 'corporate_name'], ['key', 'value']),
    }
  }
  return corporate
}
