import {
  activeOrInactive,
  billStatus,
  customerStatus,
  deliveryHeaderStatus,
  fundPaymentStatus,
  orderHeaderStatus,
  discountStatus,
  priceListStatus,
  productItemStatus,
  returHeaderStatus,
  otpStatus,
  accountStatus,
  inventoryStatus,
  deliveryLinesStatus,
  requestStatus,
  employeeStatus,
  accountingLinesCategory,
  accountingTransactionType,
  fundReceiptStatus,
  listRMAStatus,
  listRMA,
  fundReceiptCM,
} from 'constant/interfaces/ListOptions'
import { formatNpwpView, jsonStringToMap, thousandSeparator, valueFormatter } from './Helpers'

export const mappingList = (data, sourceField, expectedField) => {
  let result = []
  if (data?.length > 0 && expectedField?.length > 0) {
    result = data.map((item) => {
      if (typeof item !== 'object') return {}
      const mapResult = {}
      sourceField.forEach((row, index) => {
        if (item[row]) {
          mapResult[expectedField[index]] = item[row]
        }
      })
      return mapResult
    })
  }
  return result
}

export const mappingStatus = (target = 'activeOrInactive', formatter) => {
  let data = []
  if (target === 'activeOrInactive') data = activeOrInactive()
  if (target === 'customerStatus') data = customerStatus()
  if (target === 'priceListStatus') data = priceListStatus()
  if (target === 'productItemStatus') data = productItemStatus()
  if (target === 'discountStatus') data = discountStatus()
  if (target === 'deliveryHeaderStatus') data = deliveryHeaderStatus()
  if (target === 'deliveryLinesStatus') data = deliveryLinesStatus()
  if (target === 'orderHeaderStatus') data = orderHeaderStatus()
  if (target === 'returHeaderStatus') data = returHeaderStatus()
  if (target === 'billStatus') data = billStatus()
  if (target === 'fundPaymentStatus') data = fundPaymentStatus()
  if (target === 'otpStatus') data = otpStatus()
  if (target === 'accountStatus') data = accountStatus()
  if (target === 'inventoryStatus') data = inventoryStatus()
  if (target === 'requestStatus') data = requestStatus()
  if (target === 'employeeStatus') data = employeeStatus()
  if (target === 'accountingLinesCategory') data = accountingLinesCategory()
  if (target === 'accountingTransactionType') data = accountingTransactionType()
  if (target === 'accountingTransactionType') data = accountingTransactionType()
  if (target === 'fundReceiptStatus') data = fundReceiptStatus()
  if (target === 'listRMAStatus') data = listRMAStatus()
  if (target === 'listRMAType') data = listRMA()
  if (target === 'fundReceiptCM') data = fundReceiptCM()

  let result = []
  if (target?.length > 0) {
    result = data.map((row) => {
      const mapResult = {
        key: row,
        value: valueFormatter(formatter, row),
      }
      return mapResult
    })
  }
  return result
}

export const mappingRegionSales = (data) => {
  let rows = []
  if (data !== '') {
    const arr_region = JSON.parse(data)
    arr_region.forEach((element) => {
      rows.push({
        uid: element['region_uid'],
        region: element['region_name'],
      })
    })
  }
  return rows
}

export const mappingDataField = (data, output = 'array', join_field = '') => {
  let rows = null
  if (data !== '') {
    rows = JSON.parse(data)
    if (output === 'join') {
      let joins = []
      rows.forEach((element) => {
        for (const [key, value] of Object.entries(element)) {
          if (key === join_field) {
            joins.push(value)
          }
        }
      })
      rows = joins.join(', ')
    }
    if (output === 'bill_delivery_items') {
      let joins = []
      rows.forEach((element) => {
        let item =
          element['product_item_name'] + ' x ' + element['quantity_received'] + ' ' + element['uom']
        joins.push(item)
        rows = joins.join('\n')
      })
    }
    if (output === 'join_sales_region') {
      const arr_multi = join_field.split('|')
      let joins = []
      rows.forEach((element) => {
        let sales_region = ''
        arr_multi.forEach((key, idx) => {
          if (idx === 0) {
            sales_region += element[key]
          } else {
            sales_region += ' (' + element[key] + ')'
          }
        })
        joins.push(sales_region)
      })
      rows = joins.join(', ')
    }
  }
  return rows
}

export const mappingUOM = (data) => {
  let rows = []
  if (data !== '') {
    rows = data.split(',')
  }
  return rows
}

export const returItemStatus = (headerStatus, items) => {
  let rows = []
  items.forEach((element) => {
    // QUANTITY
    if (['REQUEST', 'APPROVED_BY_SALES'].includes(headerStatus)) {
      element.approved_uom = ''
      element.approved_quantity = 0
      element.approved_price_item = 0
      element.approved_grand_total = 0
    }
    // STATUS
    let status = 'REQUEST'
    if (['CANCELLED', 'REJECT'].includes(headerStatus)) {
      status = headerStatus
    } else if (headerStatus === 'APPROVED_BY_AUDIT') {
      status = 'CANCELLED'
      if (element?.approved_quantity > 0) {
        status = 'APPROVED'
      }
    }
    element.status = status
    rows.push(element)
  })
  return rows
}

export const dashboardSalesLine = (data) => {
  let rows = []
  const items = jsonStringToMap(data)
  if (items) {
    const months = [
      'january',
      'february',
      'march',
      'april',
      'may',
      'june',
      'july',
      'august',
      'september',
      'october',
      'november',
      'december',
    ]
    months.forEach((val) => {
      rows.push(items[0][val] ?? 0)
    })
    return rows
  } else {
    return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  }
}

const csvMappingRow = (main, obj) => {
  if (main[obj.dataIndex]) {
    let value = main[obj.dataIndex]
    if (typeof value === 'object' && value !== null) {
      value = value?.props?.children
    }
    if (['number', 'currency'].includes(obj.type)) {
      value = value.replace('Rp. ', '')
      value = value.replaceAll('.', '')
      value = parseFloat(value.replace(',', '.').replace(' ', ''))
      value = thousandSeparator(value)
    }
    if (['npwp'].includes(obj.type)) {
      value = formatNpwpView(value)
    }
    return value
  } else {
    return ''
  }
}

export const csvMappingData = (headers, data) => {
  let headerMapping = []
  let dataMapping = []
  if (data.length > 0) {
    data.forEach((main) => {
      let rows = []
      headers.forEach((obj) => {
        if (obj.export) {
          if (headerMapping.indexOf(obj.title) === -1) {
            // SET HEADER
            if (obj.type === 'children') {
              obj.children?.forEach((child) => {
                if (headerMapping.indexOf(child.title) === -1) {
                  headerMapping.push(child.title)
                }
              })
            } else {
              headerMapping.push(obj.title)
            }
          }
          if (obj.type === 'children') {
            obj.children?.forEach((child) => {
              rows.push(csvMappingRow(main, child))
            })
          } else {
            rows.push(csvMappingRow(main, obj))
          }
        }
      })
      dataMapping.push(rows)
    })
  } else {
    headers.forEach((obj) => {
      if (obj.export) {
        if (headerMapping.indexOf(obj.title) === -1) {
          // SET HEADER
          if (obj.type === 'children') {
            obj.children?.forEach((child) => {
              if (headerMapping.indexOf(child.title) === -1) {
                headerMapping.push(child.title)
              }
            })
          } else {
            headerMapping.push(obj.title)
          }
        }
      }
    })
  }
  return { header: headerMapping, body: dataMapping }
}

export const deliveryProgress = (lines) => {
  let success = 0
  let created_pickup = 0
  const total = lines.length ?? 0
  if (total > 0) {
    lines.forEach((row) => {
      if (['DELIVERED', 'CANCELLED'].includes(row.status)) {
        success += 1
      }
      if (['CREATED', 'READY_TO_SHIP'].includes(row.status)) {
        created_pickup += 1
      }
    })
  }
  return {
    total: total,
    success: success,
    created_pickup: created_pickup,
    icon: total === success ? 'check-circle' : 'truck',
    color: total === success ? '#0E9A1C' : '#FF8024',
  }
}

export const getExpedition = (obj) => {
  let text = 'PRIBADI'
  if (obj?.driver_type === 'INTERNAL') {
    text = obj?.delivery_header_corporate_name
  } else if (obj?.driver_type === '3PL') {
    text = obj.driver_third_party_logistic
  }
  return text
}

export const validationReturCompleted = (lines) => {
  let totalLineProcess = 0
  let totalValid = 0
  lines.forEach((element) => {
    if (element.returned_quantity > 0 || element.accepted_quantity > 0) {
      totalLineProcess += 1
      if (element.returned_quantity === element.accepted_quantity) {
        totalValid += 1
      }
    }
  })
  if (totalLineProcess === totalValid) {
    return true
  }
  return false
}
