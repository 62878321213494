import { useCallback } from 'react'
import { confirmationAlert, showAlert } from 'utility/SweetAlert'
import ApiService from 'services/ApiService'
import { getMessage } from 'utility/MapperResponse'
import { getTranslate } from 'utility/MapperTranslate'
import { useDispatch, useSelector } from 'react-redux'
import { setItem } from 'utility/Session'
import { FetchAccount } from 'redux/account/creator'

const InteractorAccountChangePassword = () => {
  const dispatch = useDispatch()
  const { account } = useSelector((state) => state.account)
  const accountChangePassword = useCallback(
    (payload, loading) => {
      ApiService.jsonRequest('/sso/account/login', payload, (response) => {
        loading(false)
        if (response.code === 7200) {
          ApiService.jsonRequest(
            '/sso/account/change-password',
            {
              uid: payload.uid,
              new_password: payload.new_password,
              must_change_password: payload.must_change_password,
            },
            (responseChange) => {
              if (responseChange.code === 7200) {
                if (payload.uid === account.uid && account?.must_change_password === 'Y') {
                  let expires = new Date().getTime() + 8 * 3600 * 1000 // 8 Hours
                  const profile = {
                    ...account,
                    must_change_password: 'N',
                  }
                  const data_account = {
                    key: 'wdg-account',
                    value: profile,
                    expires,
                  }
                  setItem(data_account)
                  dispatch(FetchAccount(profile))
                }

                showAlert({
                  icon: 'success',
                  message: getTranslate('message.successfully_updated'),
                  timer: 2000,
                  redirect: '/',
                })
              } else {
                showAlert({
                  icon: 'error',
                  message: getMessage(response?.msg),
                  timer: 2000,
                })
              }
            },
          )
        } else {
          showAlert({
            icon: 'error',
            message: getMessage(response?.msg),
            timer: 2000,
          })
        }
      })
    },
    [account, dispatch],
  )

  const accountChangePasswordAdmin = useCallback((payload, loading) => {
    confirmationAlert({
      message: getTranslate('message.confirm_process'),
      icon: 'question',
    }).then((result) => {
      if (result === true) {
        ApiService.jsonRequest('/sso/account/change-password', payload, (responseChange) => {
          loading(false)
          if (responseChange.code === 7200) {
            showAlert({
              icon: 'success',
              message: getTranslate('message.successfully_updated'),
              timer: 2000,
              redirect: '/administrator/account/manage',
            })
          } else {
            showAlert({
              icon: 'error',
              message: getMessage(responseChange?.msg),
              timer: 2000,
            })
          }
        })
      }
    })
  }, [])

  return { accountChangePassword, accountChangePasswordAdmin }
}

export default InteractorAccountChangePassword
