import React from 'react'
import { Link } from 'react-router-dom'
import {
  formatDate,
  getArrayString,
  getDateRange,
  getPayloadFilter,
  jsonStringToMap,
  valueFormatter,
} from 'utility/Helpers'
import { getTranslate } from 'utility/MapperTranslate'
import { verifyAccessGroup } from 'utility/Menu'
import FeatherIcon from 'feather-icons-react'
import { Button } from 'components/extras/buttons/buttons'
import { Space } from 'antd'
import { mappingList, mappingStatus } from 'utility/MapperData'
import { FilterCorporate } from 'components/extras/filterform/FilterCorporate'
import { billDateType } from 'constant/interfaces/ListOptions'

export const pathModule = '/finance/bill'

export const filterColumn = (optionsCustomer = []) => {
  const payloadFilter = getPayloadFilter('keywords')

  // DATE RANGE
  let defaultDate = {
    start: getDateRange(30, 0, 'DD/MM/YYYY').date_start,
    end: getDateRange(30, 0, 'DD/MM/YYYY').date_end,
  }
  const defaultDateStart = getDateRange(30, 0, 'DD/MM/YYYY').date_start
  const defaultDateEnd = getDateRange(30, 0, 'DD/MM/YYYY').date_end
  if (payloadFilter?.date_category === 'bill_due') {
    defaultDate.start = formatDate(
      payloadFilter?.bill_due_start ? payloadFilter?.bill_due_start : defaultDateStart,
      'DD/MM/YYYY',
      false,
      0,
    )
    defaultDate.end = formatDate(
      payloadFilter?.bill_due_end ? payloadFilter?.bill_due_end : defaultDateEnd,
      'DD/MM/YYYY',
      false,
      0,
    )
  } else if (payloadFilter?.date_category === 'invoice_issued') {
    defaultDate.start = formatDate(
      payloadFilter?.bill_date_start ? payloadFilter?.bill_date_start : defaultDateStart,
      'DD/MM/YYYY',
      false,
      0,
    )
    defaultDate.end = formatDate(
      payloadFilter?.bill_date_end ? payloadFilter?.bill_date_end : defaultDateEnd,
      'DD/MM/YYYY',
      false,
      0,
    )
  }
  // DATE RANGE
  return [
    {
      filter_target: 'input',
      initial_field: 'text_search',
      label: getTranslate('title.keyword'),
      placeholder: getTranslate('title.please_input|title.keyword'),
      required: true,
      default_value: payloadFilter?.text_search ?? null,
    },
    FilterCorporate(),
    {
      filter_target: 'select',
      label: getTranslate('field.date_category'),
      initial_field: 'date_category',
      placeholder: getTranslate('title.please_select|field.date_category'),
      defaultValue:
        payloadFilter?.date_category !== undefined
          ? payloadFilter?.date_category !== ''
            ? payloadFilter?.date_category
            : 'invoice_issued'
          : 'invoice_issued',
      allowClear: false,
      items: billDateType(),
    },
    {
      filter_target: 'datepicker',
      initial_field: 'date_range',
      label: getTranslate('title.date'),
      placeholder: null,
      type: 'range',
      dateFormat: 'DD/MM/YYYY',
      required: true,
      defaultValue: {
        start: defaultDate.start,
        end: defaultDate.end,
      },
      minDate: getDateRange(365, 60, 'DD/MM/YYYY').date_start,
      maxDate: getDateRange(365, 60, 'DD/MM/YYYY').date_end,
    },
    {
      filter_target: 'select',
      label: getTranslate('field.customer_name'),
      initial_field: 'customer_uid',
      placeholder: getTranslate('title.please_select|field.customer_name'),
      defaultValue: payloadFilter?.customer_uid !== '' ? payloadFilter?.customer_uid : null,
      required: false,
      items: mappingList(optionsCustomer, ['uid', 'customer_name'], ['key', 'value']),
    },
    {
      filter_target: 'select',
      initial_field: 'status',
      label: getTranslate('field.status'),
      placeholder: getTranslate('title.please_select|field.status'),
      required: false,
      items: mappingStatus('billStatus', 'status:bill'),
      defaultValue: payloadFilter?.status !== '' ? payloadFilter?.status : null,
    },
  ]
}

export const dataColumn = (sortedInfo, account) => {
  let columns = []
  if (process.env.REACT_APP_APPLICATION === 'WEB_CMS') {
    columns.push({
      title: getTranslate('field.corporate_name'),
      key: 'corporate_name',
      dataIndex: 'corporate_name',
      sortOrder: sortedInfo?.field === 'corporate_name' ? sortedInfo.order : false,
      sorter: true,
      type: 'text',
      export: true,
      width: 300,
    })
  }
  return [
    ...columns,
    ...(account?.corporate_configuration?.integration_service === 'ORACLE'
      ? [
          {
            title: getTranslate('field.bill_title'),
            key: 'bill_title',
            dataIndex: 'bill_title',
            sortOrder: sortedInfo?.field === 'bill_title' ? sortedInfo.order : false,
            sorter: true,
            type: 'text',
            export: true,
            width: 200,
          },
        ]
      : []),
    {
      title: getTranslate('field.invoice_issued'),
      key: 'issue_date',
      dataIndex: 'issue_date',
      sortOrder: sortedInfo?.field === 'issue_date' ? sortedInfo.order : false,
      sorter: true,
      type: 'date',
      export: true,
      width: 150,
    },
    {
      title: getTranslate('field.due_date'),
      key: 'due_date',
      dataIndex: 'due_date',
      sortOrder: sortedInfo?.field === 'due_date' ? sortedInfo.order : false,
      sorter: true,
      type: 'date',
      export: true,
      width: 200,
    },
    {
      title: getTranslate('field.invoice_code'),
      key: 'invoice_code',
      dataIndex: 'invoice_code',
      sortOrder: sortedInfo?.field === 'invoice_code' ? sortedInfo.order : false,
      sorter: true,
      type: 'text',
      export: true,
      width: 200,
    },
    {
      title: getTranslate('field.order_code'),
      key: 'order_code',
      dataIndex: 'order_code',
      sortOrder: sortedInfo?.field === 'order_code' ? sortedInfo.order : false,
      sorter: true,
      type: 'text',
      export: true,
      width: 200,
    },
    ...(account?.corporate_configuration?.integration_service === 'ORACLE'
      ? [
          {
            title: getTranslate('field.so_number'),
            key: 'number_sales_order_oracle',
            dataIndex: 'number_sales_order_oracle',
            sortOrder: sortedInfo?.field === 'number_sales_order_oracle' ? sortedInfo.order : false,
            sorter: true,
            type: 'text',
            export: true,
            width: 200,
          },
        ]
      : []),
    {
      title: getTranslate('field.delivery_lines_code'),
      key: 'lines_code',
      dataIndex: 'lines_code',
      type: 'text',
      export: true,
      width: 200,
    },
    {
      title: getTranslate('field.vat_number'),
      key: 'vat_number',
      dataIndex: 'vat_number',
      sortOrder: sortedInfo?.field === 'vat_number' ? sortedInfo.order : false,
      sorter: true,
      type: 'text',
      export: true,
      width: 250,
    },
    {
      title: getTranslate('field.vat_date'),
      key: 'vat_date',
      dataIndex: 'vat_date',
      sortOrder: sortedInfo?.field === 'vat_date' ? sortedInfo.order : false,
      sorter: true,
      type: 'date',
      export: true,
      width: 250,
    },
    {
      title: getTranslate('field.customer_name'),
      key: 'customer_name',
      dataIndex: 'customer_name',
      sortOrder: sortedInfo?.field === 'customer_name' ? sortedInfo.order : false,
      sorter: true,
      type: 'text',
      export: true,
      width: 200,
    },
    {
      title: getTranslate('field.invoice_total_amount'),
      key: 'total_amount',
      dataIndex: 'total_amount',
      sortOrder: sortedInfo?.field === 'total_amount' ? sortedInfo.order : false,
      sorter: true,
      type: 'currency',
      export: true,
      width: 200,
    },
    {
      title: getTranslate('field.buyer_bill_amount_paid'),
      key: 'total_paid',
      dataIndex: 'total_paid',
      sortOrder: sortedInfo?.field === 'total_paid' ? sortedInfo.order : false,
      sorter: true,
      type: 'currency',
      export: true,
      width: 200,
    },
    {
      title: getTranslate('field.buyer_bill_amount_unpaid'),
      key: 'total_unpaid',
      dataIndex: 'total_unpaid',
      sortOrder: sortedInfo?.field === 'total_unpaid' ? sortedInfo.order : false,
      sorter: true,
      type: 'currency',
      export: true,
      width: 200,
    },
    {
      title: getTranslate('field.status'),
      key: 'status',
      dataIndex: 'status',
      sortOrder: sortedInfo?.field === 'status' ? sortedInfo.order : false,
      sorter: true,
      type: 'status:bill',
      export: true,
      width: 150,
    },
    {
      title: getTranslate('field.created_by'),
      key: 'created_by',
      dataIndex: 'created_by',
      sorter: true,
      sortOrder: sortedInfo?.field === 'created_by' ? sortedInfo.order : false,
      type: 'text',
      export: true,
      width: 200,
    },
    {
      title: getTranslate('title.action'),
      key: 'action',
      dataIndex: 'action',
      type: 'action',
      export: false,
      width: 250,
    },
  ]
}

export const dataMapping = (
  items,
  sortedInfo,
  setBillData,
  isCSV = false,
  account,
  backHistory,
) => {
  const vatUpdate = (data) => {
    setBillData(data)
  }
  const access_control = verifyAccessGroup(pathModule, {
    view: '/view',
    update: '/update',
  })
  let result = []
  if (items?.length > 0) {
    items.forEach((rows, index) => {
      const { uid, status, total_paid, bill_delivery_lines, vat_number } = rows
      let fields = {}
      fields.key = index + 1
      dataColumn(sortedInfo, account).forEach((obj) => {
        if (rows[obj.dataIndex] !== undefined) {
          if (obj.dataIndex === 'vat_number') {
            fields[obj.dataIndex] = isCSV ? (
              vat_number ? (
                vat_number
              ) : (
                '-'
              )
            ) : (
              <Space>
                {vat_number === '' ? '-' : valueFormatter(obj.type, rows[obj.dataIndex])}
                <Button type="link" className="action-button" onClick={() => vatUpdate(rows)}>
                  <FeatherIcon icon="edit" size={18} />
                </Button>
              </Space>
            )
          } else if (obj.dataIndex === 'vat_date') {
            fields[obj.dataIndex] = (
              <Space>
                {vat_number === '' ? '-' : valueFormatter(obj.type, rows[obj.dataIndex])}
              </Space>
            )
          } else {
            fields[obj.dataIndex] = (
              <div style={{ minWidth: '50px', maxWidth: '300px', whiteSpace: 'normal' }}>
                {valueFormatter(obj.type, rows[obj.dataIndex])}
              </div>
            )
          }
        } else if (obj.dataIndex === 'lines_code') {
          const list_code =
            bill_delivery_lines !== null
              ? getArrayString(jsonStringToMap(bill_delivery_lines), 'delivery_lines_code')
              : []
          fields[obj.dataIndex] = list_code.join(', ')
        } else if (obj.dataIndex === 'total_unpaid') {
          let total_unpaid = 0
          if (rows.total_amount > rows.total_paid) {
            total_unpaid = rows.total_amount - rows.total_paid
          }
          fields[obj.dataIndex] = valueFormatter('currency', total_unpaid)
        } else if (obj.type === 'action') {
          fields.action = (
            <>
              <Space>
                {['ISSUED'].includes(status) && total_paid === 0 && access_control.update && (
                  <Link
                    className="action-button"
                    to={`${pathModule}/update?uid=${uid}${
                      backHistory ? `&back=${backHistory}` : ''
                    }`}
                  >
                    <FeatherIcon icon="edit" size={18} />
                    {getTranslate('button.edit')}
                  </Link>
                )}
                {access_control.view && (
                  <Link
                    className="action-button"
                    to={`${pathModule}/view?uid=${uid}${backHistory ? `&back=${backHistory}` : ''}`}
                  >
                    <FeatherIcon icon="eye" size={18} />
                    {getTranslate('button.detail')}
                  </Link>
                )}
              </Space>
            </>
          )
        } else {
          fields[obj.dataIndex] = rows[obj.dataIndex]
        }
      })
      result.push(fields)
    })
  }
  return result
}
