import { TOWER_ACTIVE_CATEGORY, TOWER_LIST_PRODUCT } from './action'

const initialState = {
  categoryActive: '',
  listProduct: [],
}

export default function TowerReducer(state = initialState, action) {
  switch (action.type) {
    case TOWER_ACTIVE_CATEGORY:
      return {
        ...state,
        categoryActive: action.data,
      }
    case TOWER_LIST_PRODUCT:
      return {
        ...state,
        listProduct: action.data,
      }
    default:
      return state
  }
}
