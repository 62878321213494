import Styled from 'styled-components'

const Aside = Styled.aside`
  width: 100%;
  height: 100%;
  /* background: red; */
  .ant-row{
    height: 100%;
  }
  .background-login{
    position: relative;
    height: 100%;
    /* background: red; */
  }
  @media only screen and (max-width: 575px){
    .background-login{
      height: auto;
    }
  }

  @media only screen and (max-width: 767px){
    height: 100%;
  }
  img{
    margin-bottom:24px;
  }
  .topShape {
    position: absolute;
    top: 30px;
    left: 30px;
    z-index:-1;
  }
  .bottomShape {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0px;
    width: 60%;
    z-index:-1;
  }
  @media only screen and (max-width: 767px){
    .bottomShape {
      width: 100%;
    }
  }
  .auth-side-container{
    @media only screen and (max-width: 768px){
      display: none !important;
      h1{
        font-size: 24px;
        margin-bottom: 28px;
      }
    }
  }
  .auth-side-content{
    display: flex;
    justify-content:center;
    @media only screen and (max-width: 991px){
      h1{
        font-size: 20px;
      }
    }
    @media only screen and (max-width: 767px){
      h1{
        font-size: 24px;
        margin-bottom: 28px;
      }
    }
  }
  .auth-cms{
      background-image: linear-gradient( #FF9B29, #EA4440, #FF9B29) !important;
      background-color: none !important;
    }
`

const Content = Styled.div`
    /* padding: 100px; */
    display: flex;
    align-items:center;
    flex-direction:column;
    @media only screen and (max-width: 1599px){
      padding: 50px;
    }
    @media only screen and (max-width: 991px){
      padding: 20px;
    }
    @media only screen and (max-width: 575px){
      
      padding: 20px 0px 0px 0px;
    }
    @media only screen and (max-width: 767px){
      text-align: center;
    }
    .auth-content-figure{
      @media only screen and (max-width: 1199px){
        max-width: 420px;
      }
      @media only screen and (max-width: 991px){
        max-width: 100%;
      }
    }
`
const SignIn = Styled.div`
    display: flex;
    flex-direction: column;
    min-height:100%;
    height: 100%;
    background-color: #E43A36;


    h1{
      color: white !important;
      font-weight: 600;
      margin-bottom: 0px;
      font-size:40px;
      line-height: 1;
    }

    h2{
      color: white !important;
      font-weight: 400;
      font-size: 16px;
    }

    .auth-title{
      margin-bottom: 12px;
      padding: 50px 3em; 
    }

    .auth-side-cms{
      .auth-title{
      margin-bottom: 12px;
      padding: 50px 3em;
      text-align: center; 
      }

      h1{
        color: white !important;
        font-weight: 600;
        margin-bottom: 20px;
        font-size: 40px;
        line-height: 1;
        text-align: center;
      }
      h2{
        color: white !important;
        font-weight: 400;
        text-align: center;
        font-size: 20px;

      }
    }

    .auth-side-content{
      min-height: 100%;
      height: 100%;
      width: 100%;
      
      flex-direction: column;
      justify-content: start;
      z-index:1;
    }

    .auth-content{
      display: flex;
      flex-direction: row;
      div{
        width: 100%;
        align-items: center;
        text-align: center;
      }
    }

    .auth-icon{
      width: 80px;
      margin-bottom: 6px;
    }

    .auth-illustration{
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      margin: 0px;
      z-index: 1;
    }

    .left-ornament{
      width: 150px;
      position: absolute;
      left: 0;
      bottom: 3rem;
      margin: 0px;
      z-index: 0;
    }

    .right-ornament{
      width: 150px;
      position: absolute;
      right: 0;
      top: 0;
      margin: 0px;
      z-index: 0;
    }

    .top-ornament{
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      margin: 0px;
      z-index: 0;
    }

    .bottom-ornament{
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      margin: 0px;
      z-index: 0;
    }

    .auth-title{
      width: 100%;
    }
    .auth-content{
      width: 100%;
    }
    @media only screen and (max-width: 1599px){
    }
    @media only screen and (max-width: 991px){
    }
    @media only screen and (max-width: 575px){
      
      padding: 20px 0px 0px 0px;
    }
    @media only screen and (max-width: 767px){
      text-align: center;
    }
    .auth-content-figure{
      @media only screen and (max-width: 1199px){
        max-width: 420px;
      }
      @media only screen and (max-width: 991px){
        max-width: 100%;
      }
    }
`
const AuthWrapper = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  /* padding: 40px; */
  @media only screen and (max-width: 1599px){
    padding: 0px 0px;
  }
  
  .wdg-logo{
    width: 200px; 
    margin-bottom: 0px;
    @media only screen and (max-width: 767px){
      width: 150px; 
      margin-bottom: 0px;
    }
  }

  .auth-notice{
    text-align: right;
    font-weight: 500;
    color: ${({ theme }) => theme['gray-color']};
    @media only screen and (max-width: 767px){
      text-align: center;
      margin-bottom: 10px;
    }
  }
  button{
    &.btn-signin{
      min-width: 100%;
      border-radius: 45px;
      background-image: linear-gradient(to right, #E47F36 0%, #E43A36  100%);  
    }
    &.btn-signin:disabled {
        background-color:rgb(247, 247, 248);;
        text-decoration: none;
        background-image: none;  
    }
    &.btn-create{
      border-radius: 8px;
      min-width: 205px;
    }
    &.btn-reset{
      border-radius: 8px;
      min-width: 260px;
    }
    &.ant-btn-lg{
      font-size: 14px;
      font-weight: 500;
      height: 48px;
    }
  }
  .auth-captha{
    margin-top: 15px;
    margin-bottom: 25px;
    @media only screen and (max-width: 500px) {
      .g-recaptcha {
      transform:scale(0.85);
      transform-origin:0 0;
      }
    }
  }
  .auth-contents{
    background: #FFFFFF;
    padding: 25px 100px 0px 100px;
    width: 100%;
    height: 100%;
    @media only screen and (max-width: 575px){
      padding:50px 24px;
      width: 100%;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    form{
      width: 100%;
      h1{
        font-size: 24px;
        font-weight: 600;
        @media only screen and (max-width: 767px){
          margin-bottom: 12px;
          font-size: 20px;
        }
        input::placeholder{
          color: ${({ theme }) => theme['extra-light-color']};
        }
      }
      p{
        margin-bottom: 24px;
        @media only screen and (max-width: 767px){
          margin-bottom: 12px;
          font-size: 14px;
        }
      }
      .auth-form-action{
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        @media only screen and (max-width: 379px){
          flex-flow: column;
          .forgot-pass-link{
            margin-top: 15px;
          }
        }
      }
      .ant-input:hover, .ant-input-affix-wrapper:hover {
        border-color: var(--antd-wave-shadow-color);
      }

      .ant-input:focus, .ant-input-affix-wrapper-focused, .ant-input-affix-wrapper:focus {
        border-color: var(--antd-wave-shadow-color);
        box-shadow: 0 0 0 2px rgb(242 93 39 / 20%);
      }
    }
    #forgotPass{
      .forgot-text{
        margin-bottom: 25px;
      }
      .return-text{
        margin-top: 35px;
      }
    }
    .form-divider{
      font-size: 13px;
      color: ${({ theme }) => theme['gray-solid']};
      text-align: center;
      position: relative;
      margin-bottom: 25px;
      &:before{
        content: '';
        position: absolute;
        width: 100%;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        height: 1px;
        background: ${({ theme }) => theme['border-color-light']};
      }
      span{
        background: #fff;
        padding: 0 15px;
        display: inline-block;
        position: relative;
        z-index: 2;
      }
    }
    .social-login{
      display: flex;
      align-items: center;
      margin: -6px;
      @media only screen and (max-width: 767px){
        justify-content: center;
      }
      &.signin-social{
        li{
          a{
            box-shadow: 0 5px 15px ${({ theme }) => theme['light-color']}10;
            background-color: #fff;
          }
        }
      }
      li{
        padding:6px;
        a{
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
          height: 48px;
          padding: 0 15px;
          border: 1px solid ${({ theme }) => theme['border-color-light']};
          background: ${({ theme }) => theme['bg-color-light']};
          color: ${({ theme }) => theme['text-color']};
          font-weight: 500;
          @media only screen and (max-width: 379px){
            height: 44px;
            padding: 0 12px;
          }
          span:not(.anticon){
            display: inline-block;
            margin-left: 5px;
          }
          svg,
          i{
            width: 20px;
            height: 20px;
          }
          &.google-signup,
          &.google-signin{
            display: flex;
            align-items: center;
            padding: 0 30px;
            @media only screen and (max-width: 379px){
              padding: 0 5px;
            }
            img{
              margin-right: 8px;
              @media only screen and (max-width: 379px){
                margin-right: 4px;
              }
            }
          }
          &.facebook-sign{
            color: #475993;
          }
          &.twitter-sign{
            color: #03A9F4;
          }
        }
      }
    }
  }
`
export { Aside, Content, AuthWrapper, SignIn }
