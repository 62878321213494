import { pathModule } from 'containers/finance/bill/BillInitialization'
import { useCallback } from 'react'
import ApiService from 'services/ApiService'
import { getMessage } from 'utility/MapperResponse'
import { getTranslate } from 'utility/MapperTranslate'
import { showAlert } from 'utility/SweetAlert'

const InteractorTransactionFundBillUpdate = () => {
  const transactionFundBillUpdate = useCallback((body, loading, redirect = false, path = '') => {
    const payload = {
      ...body,
    }
    ApiService.jsonRequest('/transaction/fund-bill/update', payload, (response) => {
      loading(false)
      if (response.code === 7200) {
        showAlert({
          icon: 'success',
          message: getTranslate('message.successfully_updated'),
          timer: 2000,
          reload: redirect ? false : true,
          redirect: pathModule + `/manage${path}`,
        })
      } else {
        showAlert({
          icon: 'error',
          message: getMessage(response?.msg),
          timer: 2000,
        })
      }
    })
  }, [])
  return { transactionFundBillUpdate }
}

export default InteractorTransactionFundBillUpdate
