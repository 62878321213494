import { useCallback } from 'react'
import ApiService from 'services/ApiService'
import { getMessage } from 'utility/MapperResponse'
import { getTranslate } from 'utility/MapperTranslate'
import { showAlert } from 'utility/SweetAlert'

const InteractorFundTransactionCancel = () => {
  const headerCancel = useCallback((body, loading) => {
    const payload = {
      ...body,
    }
    ApiService.jsonRequest(
      '/transaction/fund-transaction/header-cancelled',
      payload,
      (response) => {
        loading(false)
        if (response.code === 7200) {
          showAlert({
            icon: 'success',
            message: getTranslate('message.successfully_updated'),
            timer: 2000,
            reload: true,
          })
        } else {
          showAlert({
            icon: 'error',
            message: getMessage(response?.msg),
            timer: 2000,
          })
        }
      },
    )
  }, [])

  return { headerCancel }
}

export default InteractorFundTransactionCancel
