const { useCallback } = require('react')
const { default: ApiService } = require('services/ApiService')
const { getMessage } = require('utility/MapperResponse')
const { getTranslate } = require('utility/MapperTranslate')
const { showAlert } = require('utility/SweetAlert')

const InteractorWarehouseDeliveryItemsUpdateBatch = () => {
  const deliveryItemsUpdateBatch = useCallback((body, loading, callback = () => null) => {
    const payload = [...body]
    ApiService.jsonRequest('/warehouse/delivery-items/update-batch', payload, (response) => {
      loading(false)
      if (response.code === 7200) {
        showAlert({
          icon: 'success',
          message: getTranslate('message.successfully_updated'),
          timer: 2000,
          reload: true,
        })
        callback(response?.data)
      } else {
        showAlert({
          icon: 'error',
          message: getMessage(response?.msg),
          timer: 2000,
        })
        callback(null)
      }
    })
  }, [])
  return { deliveryItemsUpdateBatch }
}

export default InteractorWarehouseDeliveryItemsUpdateBatch
