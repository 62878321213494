import { DEFAULT_CURRENCY_SYMBOL } from 'constant/interfaces/ConstantValue'
import moment from 'moment'
import { getTranslate } from './MapperTranslate'
import { showAlert } from './SweetAlert'
import { Buffer } from 'buffer'

export const groupByArray = (items, key) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {},
  )

export const getArrayString = (arr, key) => {
  let result = []
  for (let index = 0; index < arr.length; index++) {
    result.push(arr[index][key])
  }
  return result
}

export const findByArray = (array, key, value) => {
  if (array) {
    return array.find((element) => {
      return element[key] === value
    })
  }
  return null
}

export const findObjInArray = (arr, key, value) => {
  let result = []
  arr.forEach((element) => {
    if (element[key] === value) {
      result.push(element)
    }
  })
  return result
}

export const objToArray = (data) => {
  let initial = []
  Object.keys(data).forEach((key) => {
    const obj = {
      name: key,
      value: data[key],
    }
    initial.push(obj)
  })

  return initial
}

export const ellipsis = (text, size) => {
  return `${text.split(' ').slice(0, size).join(' ')}...`
}

export const formatDate = (value, format, utc = false, addDay = 0, subtractDay = 0) => {
  if (value !== undefined) {
    const unix_timestamp = Date.parse(new Date(value).toString())
    let date = moment(unix_timestamp)
    if (utc) {
      date.utc()
    }
    if (addDay) {
      date.add(addDay, 'days')
    }
    if (subtractDay) {
      date.subtract(subtractDay, 'days')
    }
    return date.format(format)
  }
  return null
}

export const validateWhitespaceInput = (value) => {
  return value.replace(/^\s+|\s+$|\s+(?=\s)/g, '')
}

export const numberFormatDestroy = (value) => {
  if (!value) return 0
  const parsed = typeof value === 'string' || value instanceof String ? value : value.toString()
  return parseFloat(parsed.replace(/([^,\d]+)/g, '').replace(',', '.'))
}

export const setFormDecimal = (value) => {
  const parsed = typeof value === 'string' || value instanceof String ? value : value.toString()
  return parsed.replace('.', ',')
}

export const numberToComma = (value) => {
  if (!value) return 0
  const parsed = typeof value === 'string' || value instanceof String ? value : value.toString()
  return parsed.replace(/\B(?=(\d{6})+(?!\d))/g, ',')
}

export const getExtension = (filename) => {
  return filename.split('.').pop()
}

export const generateFilename = (name, extension) => {
  const time = moment().format('YYYYMMDDHHmmss')
  const str = name.replace(/\s+/g, '-').toUpperCase()
  return `${str}-${time}.${extension}`
}

export const generatePdfName = (flag, code) => {
  const initials = {
    invoice: 'INVOICE',
    quotation: 'SALES_ORDER',
    delivery_pickup: 'SPM',
    delivery_lines: 'SPB',
    dispatch: 'SURAT_JALAN',
    payment: 'BUKTI_PEMBAYARAN',
    proof_cash: 'BUKTI_UANG_MASUK',
    request_stock: 'PERMINTAAN',
    delivery_order: 'DELIVERY_ORDER',
    receipt: 'RECEIPT',
    inventory_letter: 'INVENTORY_LETTER',
  }
  let filename = initials[flag.toLowerCase()]
  if (!filename) {
    filename = flag
  }
  return filename.replace(/\s+/g, '-').toUpperCase() + '-' + code + '.pdf'
}

export const numberFormat = (value, options = { leadingZeroAllowed: false }) => {
  if (!value && (value !== 0 || value !== '0')) return '0'
  let parsed = typeof value === 'string' || value instanceof String ? value : value.toString()
  if (!options.leadingZeroAllowed) parsed = parsed.replace(/^[0]+/g, '')
  let number_string = parsed.replace(/[^,\d]/g, '')
  let split = number_string.split(',')
  let mod = split[0].length % 3
  let rupiah = split[0].substring(0, mod)
  let thousand = split[0].substring(mod).match(/\d{3}/gi)

  if (thousand) {
    let separates = mod ? '.' : ''
    rupiah += separates + thousand.join('.')
  }

  rupiah = split[1] !== undefined ? `${rupiah},${split[1]}` : rupiah
  return rupiah
}

export const thousandSeparator = (value) => {
  if (!value) return 0
  let splitComma = value.toString().split('.')
  splitComma[0] = splitComma[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  return splitComma.join(',')
}

export const currencySeparator = (value) => {
  let currency = value
  if (!value) {
    currency = 0
  }
  let splitComma = currency.toFixed(2).split('.')
  splitComma[0] = splitComma[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  return splitComma.join(',')
}

export const moneyFormat = (value) => {
  const lookup = [
    { value: 1e9, symbol: ' Miliar' },
    { value: 1e12, symbol: ' Triliun' },
    { value: 1e15, symbol: ' Kuadriliun' },
    { value: 1e18, symbol: ' Kuintiliun' },
  ]

  let item = lookup
    .slice()
    .reverse()
    .find((row) => {
      return value >= row.value
    })

  if (item) {
    const format = parseFloat(value / item.value).toString()
    let plus = ''
    if (format.split('.')[1]) {
      plus = '+'
    }
    return format.split('.')[0] + plus + item.symbol
  }
  return thousandSeparator(value)
}

export const removeBeforeSlash = (value) => {
  return value.replace(/^.*\/(.*)$/, '$1')
}

export const valueFormatter = (source, original_value) => {
  let value = ''
  const type = source.split(':')
  if (type[0] === 'currency') {
    value = `Rp. ${currencySeparator(original_value)}`
  } else if (type[0] === 'minus_currency') {
    value = `- Rp. ${currencySeparator(original_value)}`
  } else if (type[0] === 'number') {
    value = `${thousandSeparator(original_value)}`
  } else if (type[0] === 'translation') {
    value = getTranslate(`field.${original_value}`)
  } else if (type[0] === 'status') {
    let flag = 'general'
    if (type[1] !== undefined) {
      flag = type[1]
    }
    let initial_status = original_value.replace('-', '_').toLowerCase()
    value = getTranslate(`status.${flag + '.' + initial_status}`)
  } else if (type[0] === 'options') {
    let flag = 'general'
    if (type[1] !== undefined) {
      flag = type[1]
    }
    let initial_status = original_value.replace('-', '_').toLowerCase()
    value = getTranslate(`options.${flag + '.' + initial_status}`)
  } else if (type[0] === 'date') {
    value = formatDate(original_value, 'DD/MM/YYYY', true)
  } else if (type[0] === 'datetime') {
    value = formatDate(original_value, 'DD/MM/YYYY HH:mm', true)
  } else if (type[0] === 'npwp') {
    value = formatNpwpView(original_value)
  } else {
    value = original_value
  }
  return value
}

export const downloadByElement = (dataurl, filename) => {
  const link = document.createElement('a')
  link.href = dataurl
  link.download = filename
  link.target = '_BLANK'
  link.click()
}

export const mappingMimeToExt = (mime, mimeList) => {
  if (mime && mime !== '') {
    let allowed_extensions = []
    objToArray(mimeList).forEach((row) => {
      if (mime.split(',').includes(row.name)) {
        const extensions = row.value.split('|')
        extensions.forEach((val) => {
          allowed_extensions.push(val)
        })
      }
    })
    return allowed_extensions
  }
  return ['jpg', 'jpeg', 'gif', 'png']
}

export const maximumUpload = (file, maximumFileSize) => {
  const file_size = file.file.size
  if (file_size <= 0) {
    showAlert({
      icon: 'error',
      message: getTranslate('validation.file_corrupt'),
      timer: 0,
    })
    return false
  }
  if (file_size > 0) {
    const size_mb = file_size / 1024 / 1024
    if (size_mb > parseFloat(maximumFileSize)) {
      showAlert({
        icon: 'error',
        message: getTranslate(`validation.file_maximum|${maximumFileSize}|title.megabyte`),
        timer: 0,
      })
      return false
    }
    return true
  }
  return false
}
export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = () => resolve(reader.result)

    reader.onerror = (error) => reject(error)
  })

export const objToFormValue = (data) => {
  let initial = []
  Object.keys(data).forEach((key) => {
    const obj = {
      name: key,
      value: data[key],
    }
    initial.push(obj)
  })

  return initial
}

export const mappingSorter = (filter, obj) => {
  let sort = ''
  if (filter && obj.order) {
    let order = 'desc'
    if (obj.order === 'ascend') {
      order = 'asc'
    }
    sort = obj.field + ' ' + order
  }

  return sort
}

export const jsonStringToMap = (str) => {
  try {
    const map = JSON.parse(str)
    return map ? map : []
  } catch (e) {
    return []
  }
}

export const sumArray = (array, key) => {
  let total = 0
  if (array.length > 0) {
    total = array.reduce((a, b) => a + (b[key] || 0), 0)
  }
  return total
}

export const sumArrayNumber = (array) => {
  let total = 0
  if (array.length > 0) {
    array.forEach((val) => {
      total += val
    })
  }
  return total
}

export const currencyFormatter = (value = 0) => {
  return `${DEFAULT_CURRENCY_SYMBOL} ${currencySeparator(value)}`
}

export const downloadFile = (blob, fileName) => {
  const link = document.createElement('a')
  // create a blobURI pointing to our Blob
  link.href = URL.createObjectURL(blob)
  link.download = fileName
  // some browser needs the anchor to be in the doc
  document.body.append(link)
  link.click()
  link.remove()
  // in case the Blob uses a lot of memory
  setTimeout(() => URL.revokeObjectURL(link.href), 7000)
}

export const noNegativeNumber = (number) => {
  return Math.max(0, number.toFixed(2)) || 0
}

export const currencyFormula = (number, comma_digit = 2) => {
  return Math.max(0, parseFloat(number.toFixed(comma_digit).replace(/\.?0*$/, ''))) || 0
}

export const getDateRange = (minDay = 7, maxDay = 0, format = 'DD/MM/YYYY') => {
  let date_start = formatDate(new Date(), format, false, 0)
  let date_end = formatDate(new Date(), format, false, 0)
  if (minDay > 0) {
    date_start = formatDate(new Date(Date.now() - minDay * 24 * 60 * 60 * 1000), format, false, 0)
  }
  if (maxDay > 0 || maxDay < 0) {
    date_end = formatDate(new Date(Date.now() + maxDay * 24 * 60 * 60 * 1000), format, false, 0)
  }
  const date = {
    date_start: date_start,
    date_end: date_end,
  }
  return date
}

export const mappingDateRange = (defaultValue, obj) => {
  let dates = obj
  let obj_date = {
    date_start: defaultValue.date_start,
    date_end: defaultValue.date_end,
  }
  if (obj.date_range && obj?.date_range !== '') {
    const date_range = obj.date_range.split(' - ')
    if (date_range.length > 0) {
      obj_date = {
        date_start: date_range[0],
        date_end: date_range[1],
      }
    }
  }
  dates.date_start = obj_date.date_start
  dates.date_end = obj_date.date_end
  return dates
}

export const mappingFilterList = (data, options) => {
  let rows = []

  data.forEach((element) => {
    rows.push({
      key: element[options.key],
      value: options.translate
        ? valueFormatter(options.translate, element[options.value])
        : element[options.value],
    })
  })
  return rows
}

export const sumArrayObject = (data, options) => {
  let reducer = (previous, current) => {
    return previous + Number(current[options.key])
  }
  const total = data?.reduce(reducer, 0)
  return total
}

export const tableShowTotal = (page, limit, total) => {
  let origin = (page - 1) * limit + 1
  let destination = page * limit
  if (destination > total) {
    destination = total
  }
  return `${getTranslate('title.showing')} ${origin} - ${destination} ${getTranslate(
    'title.showing_from',
  )} ${total} ${getTranslate('title.showing_entries')}`
}

export const arrayToString = (data = [], separator = '|') => {
  if (data.length > 0) {
    return data.join(separator)
  } else {
    return ''
  }
}

export const getSumValueFromObject = (data, key) =>
  data?.reduce((accumulate, fieldData) => accumulate + (fieldData[key] || 0), 0)

export const groupByBrandProductItem = (data) =>
  data.reduce((prev, { brand_uid, brand_name, ...items }) => {
    const id = prev.findIndex((item) => item.brand_uid === brand_uid)
    id >= 0 ? prev[id].items.push(items) : prev.push({ brand_uid, brand_name, items: [items] })
    return prev
  }, [])

export const orderGroupByBrandProductItem = (data) =>
  data.reduce((prev, { item_brand_uid, item_brand_name, ...items }) => {
    const id = prev.findIndex((item) => item.item_brand_uid === item_brand_uid)
    id >= 0
      ? prev[id].items.push({
          ...items,
          item_brand_uid: item_brand_uid,
          item_brand_name: item_brand_name,
        })
      : prev.push({
          item_brand_uid,
          item_brand_name,
          items: [{ ...items, item_brand_uid: item_brand_uid, item_brand_name: item_brand_name }],
        })
    return prev
  }, [])

export const formatNpwpView = (value) => {
  if (typeof value === 'string') {
    if (value.length === 16) {
      return value
    } else {
      return value.replace(/(\d{2})(\d{3})(\d{3})(\d{1})(\d{3})(\d{3})/, '$1.$2.$3.$4-$5.$6')
    }
  }
}

export const formatNpwpInput = (value) => {
  try {
    var cleaned = ('' + value).replace(/\D/g, '')
    var match = cleaned.match(/(\d{0,2})?(\d{0,3})?(\d{0,3})?(\d{0,1})?(\d{0,3})?(\d{0,3})$/)
    if (value.length <= 15) {
      return [
        match[1],
        match[2] ? '.' : '',
        match[2],
        match[3] ? '.' : '',
        match[3],
        match[4] ? '.' : '',
        match[4],
        match[5] ? '-' : '',
        match[5],
        match[6] ? '.' : '',
        match[6],
      ].join('')
    } else if (value.length === 16) {
      return value.substr(0, 16)
    } else {
      return value.substr(0, 15)
    }
  } catch (err) {
    return ''
  }
}

export const inputNPWPString = (value) => {
  value = value.replace(/[A-Za-z\W\s_]+/g, '')
  if (value.length > 15 || value.length < 12) {
    return value
  } else {
    let split = 6
    const dots = []

    for (let i = 0, len = value.length; i < len; i += split) {
      split = i >= 2 && i <= 6 ? 3 : i >= 8 && i <= 12 ? 4 : 2
      dots.push(value.substr(i, split))
    }

    const temp = dots.join('.')
    return `${temp.substr(0, 12)}-${temp.substr(12, 7)}`
  }
}

export const formatQuantityUOM = (value) => {
  if (['KG', 'TON', 'LITER'].includes(value)) {
    return 'comma'
  }

  return 'numeric'
}

export const inputVATNumber = (value) => {
  value = value.replace(/[A-Za-z\W\s_]+/g, '')
  let split = 2
  const dots = []

  for (let i = 0, len = value.length; i < len; i += split) {
    split = i < 3 ? 3 : i <= 6 ? 5 : i >= 8 ? 8 : 2
    dots.push(value.substr(i, split))
  }
  const temp = dots.join('.')
  return temp.length > 12 ? `${temp.substr(0, 7)}-${temp.substr(7, 18)}` : temp
}

export const tableRowChildren = (data, options) => {
  let rows = []
  let show_children = options.show_children ?? 'Y'
  let sources = data
  if (options.type === 'json_string') {
    sources = jsonStringToMap(data)
  }

  sources.forEach((row) => {
    const parents = row[options.key]
    if (parents.length > 0) {
      row[options.key].forEach((element, index) => {
        let record = { ...row }
        options.fields.forEach((column) => {
          record[column] = element[column]
        })
        if (show_children === 'N') {
          if (index > 0) {
            Object.keys(record)?.forEach((obj) => {
              if (!options.fields.includes(obj)) {
                record[obj] = ''
              }
            })
          }
          rows.push(record)
        } else {
          rows.push(record)
        }
      })
    }
  })
  return rows
}

export const arrayWhereIn = (data, key, value) => {
  let rows = []

  const valueSplit = value.split('|')
  data.forEach((element) => {
    if (valueSplit.includes(element[key])) {
      rows.push(element)
    }
  })
  return rows
}

export const convertDecimal = (data) => {
  return Math.round(data * 1000000) / 1000000
}

export const jsonToBase64 = (object) => {
  if (object) {
    const json = JSON.stringify(object)
    return Buffer.from(json).toString('base64')
  } else {
    return null
  }
}

export const base64ToJson = (base64String) => {
  try {
    const json = Buffer.from(base64String, 'base64').toString()
    return JSON.parse(json)
  } catch (error) {
    return null
  }
}

export const generatePayloadFilter = (obj) => {
  const payload = {
    page: 1,
    limit: 50,
    keywords: obj.keywords,
    output: obj?.output ?? 'pagination',
    sort: '',
  }
  return jsonToBase64(payload)
}

export const getPayloadFilter = (key = '') => {
  const queryParams = new URLSearchParams(window.location.search).get('pkey')
  const obj = base64ToJson(queryParams)
  if (obj) {
    if (key !== '') {
      return obj[key]
    } else {
      return obj
    }
  }
  return null
}

export const allowEditingPrice = (allow_access, allow_category, item_category) => {
  let value = false
  if (allow_access === 'Y') {
    if (allow_category === 'ALL' || allow_category === item_category) {
      value = true
    }
  }
  return value
}

export const getMsgOrderHold = (topChecking) => {
  let data = {
    allowed_order: topChecking?.allowed_order,
    amount_due_date_full_payment: topChecking?.amount_due_date_full_payment ?? 0,
    amount_due_date_top: topChecking?.amount_due_date_top ?? 0,
    bill_information: topChecking?.bill_information,
    credit_limit_used: topChecking?.credit_limit_used ?? 0,
    message: topChecking?.message,
    oracle_credit_checking: topChecking?.oracle_credit_checking ?? 0,
    oracle_credit_hold: topChecking?.oracle_credit_hold ?? 0,
    oracle_overall_credit_limit: topChecking?.oracle_overall_credit_limit,
    oracle_tolerance_amount: topChecking?.oracle_tolerance_amount ?? 0,
    oracle_tolerance_percentage: topChecking?.oracle_tolerance_percentage ?? 0,
    oracle_trx_credit_limit: topChecking?.oracle_trx_credit_limit ?? 0,
  }

  const available_limit = data?.oracle_overall_credit_limit + data?.oracle_tolerance_amount
  let msg = getTranslate('message.over_limit') + ' ' + valueFormatter('currency', available_limit)

  if (data?.amount_due_date_top > 0) {
    msg = getTranslate('message.unpaid_total_bill')
  }

  return msg
}

export const stringToArray = (data = '', separator = '|') => {
  if (data.length > 0) {
    return data.split(separator)
  } else {
    return []
  }
}
