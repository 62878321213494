import i18n from './i18n'

// Example Use:
// console.log(getMessage('cannot_empty:corporate_type|cannot_empty:corporate_name'))
// console.log(getMessage('must_numeric:total|must_numeric:age'))
// console.log(getMessage('too_long:description;maximum_length:10|cannot_empty:category'))
// console.log(getMessage('wrong_password'))

function i18nValue(key) {
  let value = i18n.t(key)
  if (value === undefined) {
    value = key
  }
  return value
}

export const getMessage = (msg) => {
  let message = ''
  if (msg !== '') {
    const list_msg = msg.split('|')
    let message_value = []
    list_msg.forEach((val) => {
      if (val.includes('cannot_empty:')) {
        message_value.push(
          `${i18nValue(`field.${val.replace('cannot_empty:', '')}`)} ${i18nValue(
            'message.cannot_empty',
          )}`,
        )
      } else if (val.includes('must_numeric:')) {
        message_value.push(
          `${i18nValue(`field.${val.replace('must_numeric:', '')}`)} ${i18nValue(
            'message.must_numeric',
          )}`,
        )
      } else if (val.includes('insufficient_stock:')) {
        message_value.push(
          `${i18nValue(`${val.replace('insufficient_stock:', '')}`)} ${i18nValue(
            'message.insufficient_stock',
          )}`,
        )
      } else if (val.includes('stock_not_available:')) {
        message_value.push(
          `${i18nValue(`${val.replace('stock_not_available:', '')}`)} ${i18nValue(
            'message.stock_not_available',
          )}`,
        )
      } else if (val.includes('too_long:')) {
        const longs = val.split(';')
        if (longs.length > 1) {
          const field = longs[0].replace('too_long:', '')
          const max = longs[1].replace('maximum_length:', '')
          message_value.push(
            `${i18nValue(`field.${field}`)} ${i18nValue('message.too_long')} ${i18nValue(
              'message.maximum_long_character',
            )}: ${max}`,
          )
        }
      } else if (val.includes('data_not_found:')) {
        const value = val.replace('data_not_found:', '')
        const valueSplit = value.split(',')

        message_value.push(
          `${i18nValue('message.data_not_found')}: ${i18nValue(`${valueSplit.join(', ')}`)} `,
        )
      } else if (val.includes('invalid_date_format:')) {
        const value = val.replace('invalid_date_format:', '')
        const valueSplit = value.split(',')

        message_value.push(
          `${i18nValue('message.invalid_date_format')}: ${i18nValue(`${valueSplit.join(', ')}`)} `,
        )
      } else if (val.includes('invalid_format:')) {
        const value = val.replace('invalid_format:', '')
        const valueSplit = value.split(',')

        message_value.push(
          `${i18nValue('message.invalid_format')}: ${i18nValue(`${valueSplit.join(', ')}`)} `,
        )
      } else if (val.includes('bill_already_processed:')) {
        const value = val.replace('bill_already_processed:', '')
        const valueSplit = value.split(',')

        message_value.push(
          `${i18nValue('response_message.bill_already_processed')}: ${i18nValue(
            `${valueSplit.join(', ')}`,
          )} `,
        )
      } else if (val.includes('bill_overpayment:')) {
        const value = val.replace('bill_overpayment:', '')
        const valueSplit = value.split(',')

        message_value.push(
          `${i18nValue('response_message.bill_overpayment')}: ${i18nValue(
            `${valueSplit.join(', ')}`,
          )} `,
        )
      } else {
        message_value.push(i18nValue(`response_message.${val}`))
      }
      message = message_value.join(', ')
    })
  }
  return message
}
