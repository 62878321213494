import { MENU_ACCESS } from './action'
import { getItem } from '../../utility/Session'

const initialState = {
  menu_access: getItem('wdg-access'),
}

export default function AccessReducer(state = initialState, action) {
  switch (action.type) {
    case MENU_ACCESS:
      return {
        ...state,
        menu_access: action.data,
      }
    default:
      return state
  }
}
