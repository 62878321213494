import React from 'react'
import { SignIn } from './style'

const CMS = () => {
  return (
    <SignIn
      className="auth-cms"
      style={{ backgroundColor: 'linear-gradient( #FF9B29, #EA4440, #FF9B29) !important;' }}
    >
      <div className="auth-side-content auth-side-cms">
        <div className="auth-title">
          <h1>Content Management System</h1>
          <h2>PT. Widea Dagang Nusantara</h2>
        </div>
      </div>
      <img
        src={require('../../assets/img/auth/cms/top-ornament.png')}
        alt="wave-illustration"
        className="top-ornament"
      />
      <img
        src={require('../../assets/img/auth/cms/bottom-ornament.png')}
        alt="wave-illustration"
        className="bottom-ornament"
      />
      <img
        src={require('../../assets/img/auth/cms/auth-illustration.png')}
        alt="wave-illustration"
        className="auth-illustration"
      />
    </SignIn>
  )
}

export default CMS
