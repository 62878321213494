import { useCallback } from 'react'
import ApiService from 'services/ApiService'
import { getMessage } from 'utility/MapperResponse'
import { getTranslate } from 'utility/MapperTranslate'
import { confirmationAlert, showAlert } from 'utility/SweetAlert'

const InteractorTransactionRMACreate = () => {
  const transactionRMACreate = useCallback((body, loading, path = '') => {
    const payload = {
      ...body,
    }

    confirmationAlert({
      message: getTranslate('message.confirm_process'),
      icon: 'question',
    }).then((result) => {
      if (result === true) {
        ApiService.jsonRequest('/transaction/rma-header/create', payload, (response) => {
          loading(false)
          if (response.code === 7201) {
            let timer = 2000
            let msg = getTranslate('message.successfully_created')
            showAlert({
              icon: 'success',
              message: msg,
              timer: timer,
              redirect: `/transaction/rma/manage${path}`,
            })
          } else {
            showAlert({
              icon: 'error',
              message: getMessage(response?.msg),
              timer: 3000,
            })
          }
        })
      } else {
        loading(false)
      }
    })
  }, [])
  return { transactionRMACreate }
}

export default InteractorTransactionRMACreate
