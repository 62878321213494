import { useCallback } from 'react'
import ApiService from 'services/ApiService'
import { getMessage } from 'utility/MapperResponse'
import { getTranslate } from 'utility/MapperTranslate'
import { confirmationAlert, showAlert } from 'utility/SweetAlert'

const InteractorWarehouseLoadingProcess = () => {
  const warehouseLoadingProcess = useCallback((body, loading, reload = false) => {
    const payload = {
      ...body,
    }
    confirmationAlert({
      message: getTranslate('message.confirm_process'),
      icon: 'question',
    }).then((result) => {
      if (result === true) {
        ApiService.jsonRequest('/warehouse/delivery-lines/loading-process', payload, (response) => {
          loading(false)
          if (response.code === 7200) {
            showAlert({
              icon: 'success',
              message: getTranslate('message.successfully_updated'),
              timer: 2000,
              redirect: !reload ? `/warehouse/delivery-schedule/manage` : false,
              reload,
            })
          } else {
            showAlert({
              icon: 'error',
              message: getMessage(response?.msg),
              timer: 2000,
            })
          }
        })
      } else {
        loading(false)
      }
    })
  }, [])
  return { warehouseLoadingProcess }
}

export default InteractorWarehouseLoadingProcess
