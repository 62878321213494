import { combineReducers } from 'redux'
import Theme from './theme/reducers'
import LoadingReducer from './loading/reducers'
import AccountReducer from './account/reducers'
import AccessReducer from './access/reducers'
import MenuReducer from './menu/reducers'
import ShippingReducer from './shipping/reducers'
import NotificationReducer from './notification/reducers'
import TowerReducer from './tower/reducers'

const rootReducers = combineReducers({
  theme: Theme,
  loading: LoadingReducer,
  access: AccessReducer,
  menu: MenuReducer,
  account: AccountReducer,
  shipping: ShippingReducer,
  notification: NotificationReducer,
  tower: TowerReducer,
})

export default rootReducers
