import { useCallback } from 'react'
import ApiService from 'services/ApiService'
import { getMessage } from 'utility/MapperResponse'
import { getTranslate } from 'utility/MapperTranslate'
import { showAlert } from 'utility/SweetAlert'

const InteractorWarehouseDeliveryLinesUpdateDate = () => {
  const deliveryLinesUpdateDate = useCallback(
    (body, loading, redirect = true, callback = () => {}) => {
      const payload = {
        ...body,
      }
      const isAlert = body.alert ?? false
      ApiService.jsonRequest('/warehouse/delivery-lines/update-date', payload, (response) => {
        loading(false)
        if (response.code === 7200) {
          if (isAlert) {
            showAlert({
              icon: 'success',
              message:
                payload.is_deleted === 'Y'
                  ? getTranslate('message.successfully_deleted')
                  : getTranslate('message.successfully_updated'),
              timer: 2000,
              redirect: redirect ? `/warehouse/delivery-schedule/manage` : false,
              reload: body.reload ?? false,
            })
          }
          callback(payload)
        } else {
          showAlert({
            icon: 'error',
            message: getMessage(response?.msg),
            timer: 2000,
          })
        }
      })
    },
    [],
  )
  return { deliveryLinesUpdateDate }
}

export default InteractorWarehouseDeliveryLinesUpdateDate
