import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import InteractorMasterMenuSearch from './InteractorMasterMenuSearch'
import { StoreAccess } from 'redux/access/creator'
import { StoreMenu } from 'redux/menu/creator'
import { HideLoading } from 'redux/loading/creator'
import { findByArray } from 'utility/Helpers'
import { setItem } from 'utility/Session'
import { getMenu } from 'utility/Menu'

const InteractorMasterMenuApplication = () => {
  const { menuSearch } = InteractorMasterMenuSearch()
  const { account } = useSelector((state) => state.account)
  const dispatch = useDispatch()

  const handleResponse = useCallback(
    (data) => {
      const recursion = (items, id = null, link = 'menu_parent_uid') =>
        items
          .filter((item) => item[link] === id)
          .map((item) => {
            let tree = { ...item }
            tree.children = null
            const children = recursion(items, item.uid)
            if (children.length > 0) {
              tree.children = children
            }
            return tree
          })

      // FIND ACCESS
      let mapping = []
      account.account_menu.forEach((row) => {
        const menu_uid = findByArray(data, 'uid', row.menu_uid)
        if (menu_uid) {
          const { created_by, created_date, status, updated_by, ...data_menu } = menu_uid
          if (data_menu.menu_parent_uid && data_menu.menu_parent_uid !== '') {
            const from_parent = findByArray(data, 'uid', data_menu.menu_parent_uid)
            if (from_parent?.menu_parent_uid === '') {
              from_parent.menu_parent_uid = null
            }
            mapping.push(from_parent)
          } else {
            data_menu.menu_parent_uid = null
          }
          mapping.push(data_menu)
        }
      })

      // MAIN LEVEL
      mapping.forEach((row) => {
        if (row.menu_parent_uid) {
          const main_level = findByArray(data, 'uid', row.menu_parent_uid)
          if (main_level) {
            if (main_level.menu_parent_uid === '') {
              main_level.menu_parent_uid = null
            }
            mapping.push(main_level)
          }
        }
      })

      // GROUP
      const access = Array.from(new Set(mapping.map((a) => a.uid))).map((id) => {
        return mapping.find((a) => a.uid === id)
      })

      // SORT
      access.sort((a, b) => (a.menu_level > b.menu_level ? 1 : -1))
      access.sort((a, b) => (a.menu_sort_number > b.menu_sort_number ? 1 : -1))

      // ROUTE PROTECTED
      setItem({
        key: 'wdg-access',
        value: access,
      })
      dispatch(StoreAccess(access))

      // MENU
      const result = recursion(access)
      setItem({
        key: 'wdg-menu',
        value: getMenu(result),
      })
      dispatch(StoreMenu(getMenu(result)))
      dispatch(HideLoading())
    },
    [account, dispatch],
  )
  const menuApplication = useCallback(() => {
    menuSearch(
      {
        keywords: {
          application_initial: process.env.REACT_APP_APPLICATION,
          status: 'ACTIVE',
        },
        output: 'list',
      },
      handleResponse,
      () => null,
    )
  }, [handleResponse, menuSearch])

  return { menuApplication }
}

export default InteractorMasterMenuApplication
