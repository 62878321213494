import { useCallback } from 'react'
import ApiService from 'services/ApiService'

const InteractorTowerDashboardWebNew = () => {
  const towerDashboardWebNew = useCallback((body, callback, loading) => {
    const payload = {
      ...body,
    }
    ApiService.jsonRequest('/tower/dashboard/web-new', payload, (response) => {
      loading(false)
      if (response.code === 7200) {
        callback(response.data)
      } else {
        callback(null)
      }
    })
  }, [])
  return { towerDashboardWebNew }
}

export default InteractorTowerDashboardWebNew
