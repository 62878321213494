import { Card, Steps } from 'antd'
import Styled from 'styled-components'

const StyleIndex = Styled.div`
    .ant-table-content::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    
    .ant-table-content::-webkit-scrollbar:vertical {
        width: 16px;
    }
    
    .ant-table-content::-webkit-scrollbar:horizontal {
        height: 16px;
    }
    
    .ant-table-content::-webkit-scrollbar-thumb {
        background-color: #E6E6E6;
        border-radius: 10px;
        border: 2px solid #F5F5F5;
    }
    
    .ant-table-content::-webkit-scrollbar-track {
        background-color: #F5F5F5; 
    }

    &.active{
        color: green;
        text-decoration: line-through;
    }
    .ant-input-search-button {
        height: 42px !important;
        width: 50px;
        color: #FFFFFF;
        background: var(--antd-wave-shadow-color);
        border: 1px solid var(--antd-wave-shadow-color);
    }
    .ant-input-affix-wrapper{
        padding:0 10px 0 5px !important;
    }
    .ant-input-group-addon{
        background: none;
        box-shadow: none; 
    }
    .btn-primary{
        a{
            display: inline-flex;
            align-items: center;
        }
    }
    .search-box{
        position: relative;
        box-shadow: 0 5px 5px rgba(#9299B8,.3);
        @media (max-width: 767px) {
            margin-bottom: 12px;
        }
        .search-icon{
            position: absolute;
            ${({ theme }) => (theme.rtl ? 'right' : 'left')}: 18px;
            top: 50%;
            transform: translateY(-50%);
            svg,
            img{
                margin-top: 6px;
                min-width: 16px;
                color: #9299B8;
            }
        }
        input{
            border: 0 none;
            height: 42px;
            width: 100%;
            max-width: 300px;
            padding: ${({ theme }) => (theme.rtl ? '0 20px' : '0 20px')};
            border-radius: 6px;
            &::placeholder{
                color: #ADB4D2;
            }
            &:focus{
                outline: none;
            }
        }
    }
    .ant-table{
        .ant-image-img{
            max-width: 64px;
        }
    }

    .ant-table-content {
    display: block;
    width: 100%;
    overflow-x: auto;
    }

    .table-responsive .ant-table-content .ant-table-cell {
    white-space: nowrap;
    }

    .ant-pagination-item-active a:hover {
        color: #fff;
        font-weight: 500;
    }

    .ant-table-tbody > tr > td {
        // white-space: nowrap;
    }


    .fixed-header-table .ant-table-tbody > tr > td {
        // white-space: pre-wrap;
    }

    .state-illustration {
        display: flex;
        justify-content: center;
        align-items: center;
        
        img {
            width: 50%;
        }
    }

    .state-text-wrapper {
        margin-top: 10px;
        text-align: center;
    }

    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        border-color: var(--antd-wave-shadow-color);
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-color: var(--antd-wave-shadow-color);
        box-shadow: 0 0 0 2px rgb(242 93 39 / 20%);
    }

    .action-button {
        display: flex;
        justify-content: center;
        font-weight: 500;
        padding: 0;
        
        svg:not(:root) {
            margin-right: 5px;
        }
    }
    .ant-checkbox-wrapper:hover{
        border-color: var(--antd-wave-shadow-color);
    }
    .ant-checkbox-inner:hover, .ant-tree-checkbox-inner:hover {
        border-color: var(--antd-wave-shadow-color);
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-tree-checkbox:hover .ant-tree-checkbox-inner {
        border-color: var(--antd-wave-shadow-color);
    }
    .ant-checkbox-checked .ant-checkbox-inner, .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
        background-color: var(--antd-wave-shadow-color);
        border-color: var(--antd-wave-shadow-color);
    }
    .ant-checkbox-checked::after, .ant-tree-checkbox-checked::after {
        border: 1px solid var(--antd-wave-shadow-color);
    }
    .ant-checkbox-indeterminate .ant-checkbox-inner::after {
        background-color: #E43A36;
    }
    .ant-tree-checkbox-inner::after {
        background-color: var(--antd-wave-shadow-color);
    }
    .ant-form input[type='checkbox']:focus {
        outline: 5px auto var(--antd-wave-shadow-color);
    }
    .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: var(--antd-wave-shadow-color);
    }
`
const StyleForm = Styled.div`
    .form-input{
        .info{
            background-color: transparent;
        }
        .ant-table-content {
            overflow-x: auto;
            margin: 24px 0;
        }
        .ant-form-item-label {
            text-align: unset;
        }
    }
    .form-input.overflow-view {
        max-height: 100vh;
        overflow-x: hidden;
    }
    .record-form-actions{
        padding-right: 0px !important;
        button:not(:last-child){
            margin-right: 20px;
            @media only screen and (max-width: 767px) {
                margin-right: 0px;
            }
        }
        @media only screen and (max-width: 767px) {
            text-align: center;
            button{
                ${({ theme }) => (!theme.rtl ? 'margin-left' : 'margin-right')}: 6px;
            }
        }
    }
    .anticon.ant-input-clear-icon:hover, .ant-select-clear:hover, .ant-select-multiple .ant-select-selection-item-remove:hover {
        color: #ff6b72;
    }
    .stripe-card {
        border-radius: 8px;
        border-left: 10px solid #E43A36;
        border-top: 1px solid #E43A36;
        border-bottom: 1px solid #E43A36;
        border-right: 1px solid #E43A36;
        padding: 20px 10px;
    }
    .stripe-card-radio {
        overflow: hidden;
        border-radius: 8px;
        border-left: 10px solid #F8AE93;
        border-top: 1px solid #F8AE93;
        border-bottom: 1px solid #F8AE93;
        border-right: 1px solid #F8AE93;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 10px;
        max-width: 100%;
    }
    .ant-radio-wrapper-checked.stripe-card-radio {
        border-color: #E43A36;
    }
    .stripe-card .ant-radio {
        margin-right: 10px; 
    }
    .stripe-card .content-radio {
        width: 100%;
        display: block;
    }
    .stripe-card.ant-radio-wrapper-checked {
        background-color: #FEEFE9;
        border-left: 10px solid #E43A36;
        border-top: 1px solid #E43A36;
        border-bottom: 1px solid #E43A36;
        border-right: 1px solid #E43A36;
    }
    .stripe-card .ant-radio-input:focus + .ant-radio-inner,
    .stripe-card .ant-radio:hover .ant-radio-inner,
    .stripe-card .ant-radio-checked .ant-radio-inner,
    .stripe-card .ant-radio-checked::after {
        border-color: #E43A36;
    }

    .stripe-card .ant-radio-inner::after {
        background-color: #E43A36;
    }
    .stripe-card .radio-image-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .stripe-card .radio-image {
        border-radius: 50%;
    }
    .stripe-card .ant-tag {
        margin-bottom: 5px;
    }
    .stripe-card-radio .ant-radio-checked .ant-radio-inner,
    .stripe-card-radio .ant-radio:hover .ant-radio-inner {
        border-color: #E43A36;
    }
    .stripe-card-radio .ant-radio-checked .ant-radio-inner:after {
        background-color: #E43A36;
    }
    .ant-upload-select-picture-card:hover {
        border-color: var(--antd-wave-shadow-color);
    }
    .pro-image{
        position: relative;
        margin-bottom: 30px;
        .ant-spin.ant-spin-spinning{
            position: absolute;
            top: 0;
            ${({ theme }) => (theme.rtl ? 'right' : 'left')}: 0;
            width: 120px;
            height: 120px;
            background: #ffffff90;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            z-index: 10;
            &:after{
                position: absolute;
                ${({ theme }) => (theme.rtl ? 'right' : 'left')}: 0;
                top: 0;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                background-color: #272B4120;
                content: '';
                z-index: -1;
            }
            .ant-spin-dot {
                position: relative;
                z-index: 10;
            }
        }
        img{   
            max-width: 120px;
            min-width: 120px;
            min-height: 120px;
            border-radius: 50%;
        }
        .ant-spin{
            height: 120px;
            width: 120px;
            display: flex;
            align-items: center;
        }
        .ant-upload-select{
            position: absolute;
            ${({ theme }) => (theme.rtl ? 'right' : 'left')}: 80px;
            bottom: -5px;
            height: 40px;
            width: 40px;
            background: #fff;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            z-index: 222;
            span{
                display: inline-flex;
                height: 32px;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                width: 32px;
                background:  ${({ theme }) => theme['primary-color']};
            }
        }
        .upload-btn{
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
        }
        .info{
            ${({ theme }) => (theme.rtl ? 'margin-right' : 'margin-left')}: 20px;
            h1{
                font-size: 15px;
                font-weight: 500;
                margin-bottom: 0;
            }
        }
        .ant-upload-list-item{
            margin-top: 0;
            &:hover{
                .ant-upload-list-item-info{
                    background-color: transparent;
                }
            }
            .ant-upload-list-item-info{
                >span{
                    display: flex;
                    align-items: center;
                    ${({ theme }) => (theme.rtl ? 'padding-right' : 'padding-left')}: 14px;
                    ${({ theme }) => (!theme.rtl ? 'padding-right' : 'padding-left')}: 10px;
                }
                .ant-upload-list-item-card-actions {
                    /* // top: -8px; */
                }
            }
        }
    }
    .record-spin{
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ant-checkbox-wrapper{
        display: flex;
    }
    .ant-radio-button-wrapper {
        min-width: 100px;
        text-align: center;
    }
    .ant-col, .ant-input-number{
        width: 100%;
    }
    .ant-input-focused, .ant-input:focus {
        border-color: var(--antd-wave-shadow-color);
        box-shadow: 0 0 0 2px rgb(242 93 39 / 20%);
    }
    .ant-input:hover {
        border-color: var(--antd-wave-shadow-color);
    }
    .ant-checkbox-wrapper:hover{
        border-color: var(--antd-wave-shadow-color);
    }
    .ant-checkbox-inner:hover, .ant-tree-checkbox-inner:hover {
        border-color: var(--antd-wave-shadow-color);
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-tree-checkbox:hover .ant-tree-checkbox-inner {
        border-color: var(--antd-wave-shadow-color);
    }
    .ant-checkbox-checked .ant-checkbox-inner, .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
        background-color: var(--antd-wave-shadow-color);
        border-color: var(--antd-wave-shadow-color);
    }
    .ant-checkbox-checked::after, .ant-tree-checkbox-checked::after {
        border: 1px solid var(--antd-wave-shadow-color);
    }
    .ant-checkbox-indeterminate .ant-checkbox-inner::after {
        background-color: #E43A36;
    }
    .ant-tree-checkbox-inner::after {
        background-color: var(--antd-wave-shadow-color);
    }
    .ant-form input[type='checkbox']:focus {
        outline: 5px auto var(--antd-wave-shadow-color);
    }
    .ant-input-affix-wrapper-focused, .ant-input-affix-wrapper:focus {
        box-shadow: rgba(274, 100, 62 , 0.2) 0px 0px 0px 2px;
        border: 1px solid #E43A36;
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        box-shadow: rgba(274, 100, 62 , 0.2) 0px 0px 0px 2px;
        border: 1px solid #E43A36;
    }
    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background-color: rgba(274, 100, 62 , 0.1);
        color: #E43A36;
    }
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        border-color: var(--antd-wave-shadow-color);
    }
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
        border: 1px solid #E43A36;
    }
    .ant-picker{
        &:hover {
            border-color: var(--antd-wave-shadow-color);
        }
        border-color: #E3E6EF;
        &.ant-picker-focused {
            border-color: var(--antd-wave-shadow-color);
            box-shadow: 0 0 0 2px rgb(242 93 39 / 20%);
            .ant-picker-separator {
                color: var(--antd-wave-shadow-color);
            }
        }
        .ant-picker-clear:hover {
            color: #ff6b72;;
        }
    }
    .ant-input-affix-wrapper:focus {
        border-color: var(--antd-wave-shadow-color);
        box-shadow: 0 0 0 2px rgb(242 93 39 / 20%)
    }
    .ant-input-number-focused {
        box-shadow: 0 0 0 2px rgb(242 93 39 / 20%);
    }
    .ant-input-number:hover, .ant-input-number-focused {
        border-color: var(--antd-wave-shadow-color);
    }
    .ant-input-number-handler:hover .ant-input-number-handler-up-inner {
        color: #E43A36;
    }
    .ant-input-number-handler:hover .ant-input-number-handler-down-inner {
        color: #E43A36;
    }
    .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: var(--antd-wave-shadow-color);
    }
    .form-additional-button {
        align-items: center;
        display: flex;
        justify-content: left;
    }
    .ant-divider-horizontal.ant-divider-with-text-left::before
    { 
        top: 0; width: 5%;
    }
    .ant-divider-horizontal.ant-divider-with-text-left::after,
    .ant-divider-horizontal.ant-divider-with-text-right::before 
    { 
        top:0; width: 95%;
    }
    .ant-divider-horizontal.ant-divider-with-text{
        margin: 8px 0px;
    }
    .input-date{
        width:100%;
    }

    .ant-divider-inner-text {
        .ant-checkbox-wrapper{
            .ant-checkbox + span{
                color: #FBCEBE;
            }
            .ant-checkbox-checked + span{
                    color: #E43A36;
            }
        }
    }
    @media only screen and (max-width: 575px) {
        .input-with-checkbox{
            margin-bottom:4px;
        }
    }
    .custom-input{
        border: 1px solid #e6ebf1;
        padding: 6px;
        border-radius: 8px ;
    }
    .form-view{
        .ant-col{
            >div {
                display: inline-block;
            }
            word-break: keep-all;
            &:nth-child(even)::before{
                margin-right: 6px;
                content: ":";
            }
            &:nth-last-child(3){
                position: relative;
            }
            &:nth-last-child(2)::before{
                display: none;
            }
        }
    }
    .ant-tabs-tab {
        &.ant-tabs-tab-active .ant-tabs-tab-btn {
            color: var(--antd-wave-shadow-color);
        }
        :hover {
            color: var(--antd-wave-shadow-color);
        }
    }
    .ant-tabs-ink-bar {
        background-color: var(--antd-wave-shadow-color);
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
        background: var(--antd-wave-shadow-color);
        border-color: var(--antd-wave-shadow-color);
        :hover {
            background: var(--antd-wave-shadow-color);
        }
    }
    .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
        border-right-color: var(--antd-wave-shadow-color);
    }
    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
        border-right-color: var(--antd-wave-shadow-color);
        border-color: var(--antd-wave-shadow-color);
    }
    .ant-radio-button-wrapper:hover{
        color: var(--antd-wave-shadow-color);
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before,.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before{
        background-color: var(--antd-wave-shadow-color);
    }
    .input-table-block{
        width:  100%;
        .ant-col-md-14{
            @media only screen and (max-width: 767px) {
                max-width: 100% !important;
            }
            max-width: 100% !important;
        }
        .ant-col-sm-16{
            @media only screen and (max-width: 575px) {
                max-width: 100% !important;
            }
            max-width: 100% !important;
        }
    }
`
const StyleView = Styled.div`

    .dashboard-container{
        display: flex !important;
        justify-content: flex-end;
    }

    .wdg-radio-container{
        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before{
            background-color: transparent !important;
        }
        .ant-radio-button-wrapper-checked{
            color: #FF9B29 !important;
            background-color: #FFF4E8 !important;
            border: solid 2px #FF9B29 !important;
        }
    }

    .wdg-radio-button{
        border-radius: 5px !important;
        margin: 0px 10px;
        color: #FF9B29;
    }
    
    .sales-card-title{
        .ant-card-head-title{
            font-weight: 500;
            margin-bottom: 16px;
        }
    }

    .summary-card-section{
        .ant-card-head{
            padding:0px 8px;
        }
        .ant-card-body{
            padding: 5px;
        }
    }
    .summary-card-title-placeholder{
        display: flex;
        align-items: center;
        margin-bottom: 0.5em;
    }
    .summary-card-title{
        font-weight: 500;
        font-size: 12px;
    }
    .summary-card-highlight{
       color: #EC221F;
    }

    .dashboard-card{
        border: 1px solid rgba(199, 200, 205, .5) !important;
        border-radius: 5px;
        padding: 5px;
        .ant-card-body{
            padding: 5px;
        }
    }

    img{
        height: 100%;
        max-width: 120px;
    }
    .card-image-profile{
        width: 120px;
        height: 120px;
        img {
            width: 100%;
            object-fit: cover;
        }
    }
    .title{
        width:100%;
        text-align:center;
        p{
            margin: 0px;
            font-weight:light;
            color: #999999;
            &:first-child{
                font-weight:bold;
                font-size:18px;
                color: #000000;
            }
        }
    }
    .ant-card{
        @media only screen and (max-width: 767px) {
            min-height: 80vh;
        }
        .ant-card-head-title{
            p{
                margin-bottom: 0px;
            }
            span{
                margin-left: 0px;
                font-size: 14px;
            }
        }
        @media only screen and (max-width: 575px){
            .ant-card-head-wrapper{
                flex-flow: row;
            }
            img{
                height: 100%;
                max-width: 90px;
            }
        }
    }
    .form-view{
        .ant-col{
            >div {
                display: inline-block;
            }
            word-break: keep-all;
            word-wrap: break-word;
            &:nth-child(even)::before{
                margin-right: 6px;
                content: ":";
            }
          
        }
        .image-col-view{
            &:nth-child(even)::before{
                position: absolute;
                top: 0px;
                left: 0px;
            }
        }
        .extra-button {
            height: 0;
            padding: 4px;
        }
        @media only screen and (max-width: 575px) {
            .extra-button {
                padding: 8px;
            }
        }
    }
    .action-button {
        display: flex;
        justify-content: center;
        font-weight: 500;
        padding: 0;
        
        svg:not(:root) {
            margin-right: 5px;
        }
    }
    .ant-table-content {
        overflow-x: auto;
    }
    .form-additional-button-center{
        align-items: center;
        display: flex;
        justify-content: left;
    }

    .card-view-secondary {
        padding: 20px 12px;
        overflow: hidden;
        border-radius: 8px;
        border-left: 10px solid var(--antd-wave-shadow-color);
        border-top: 1px solid var(--antd-wave-shadow-color);
        border-bottom: 1px solid var(--antd-wave-shadow-color);
        border-right: 1px solid var(--antd-wave-shadow-color);

        .card-statistics-content {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            &.card-group {
                justify-content: space-between;
            }

            .icon-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: var(--antd-wave-shadow-color);
                border-radius: 50%;
                margin-right: 10px;
                padding: 12px;
            }

            h2, h5 {
                margin: 0;
            }

            h2 {
                color: #4f4f4f;
                font-weight: 600;
            }

            h5 {
                color: #898989;
            }
        }

        .vl {
            border-right: 1px solid red;
            content: '';
            height: 100%;
        }

        .hl {
            border-bottom: 1px solid red;
            content: '';
            width: 100%;
            margin: 5px 0;
        }

        .button-no-wrapper {
            display: flex;
            align-items: center;
            justify-content: end;
            margin-top: 10px;

            p {
                margin: 0;
                margin-right: 10px;
                color: var(--antd-wave-shadow-color);
                font-weight: 600;
            }
        }
    }
    .text-wrap {
            word-wrap: break-word;
    }
    
`
const StyleCardWrapper = Styled.div`
    .ant-card{
        background: #F8F9FB;
    }
    .ant-card-head{
        border-color: #E3E6EF;
        background: #F8F9FB;
    }
    .ant-card-head .ant-card-head-title{
        padding: 12px 0;
    }
    .ant-card-head .ant-card-extra{
        display: block;
        padding: 12px 0;
        @media only screen and (max-width: 575px){
            margin-bottom: 4px;
    }
    }
    .ant-card.ant-card-bordered .ant-card-head{
        background: #fff;
    }
    .ant-card-head-wrapper .ant-card-extra a{
        color: #5F63F2;
    }
    .ant-card-body{
        padding: 22px 25px 15px 25px !important
        .ant-tree{
            background: #F8F9FB; 
        }
    }
    .ant-card-body p{
        margin-bottom: 4px;
        color: ${({ theme }) => theme['gray-color']};
    }
    .ant-card.ant-card-bordered {
        border-radius: 5px;
        overflow: hidden;
        background: #fff;
        border: 1px solid #E3E6EF !important;
    }
    .ant-card-small > .ant-card-head{
        font-size: 16px;
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .ant-card-small > .ant-card-body{
        padding: 12px 15px 8px 15px !important
    }
`
const StyleSteps = Styled(Steps)`
    padding: 1.5rem;
    background-color: var(--antd-wave-shadow-color);
    border-radius: 8px;

    .ant-steps-item-finish {
        .ant-steps-item-icon {
            border-color: #F9971B;

            .ant-steps-icon {
                color: var(--antd-wave-shadow-color);
            }
        }

        .ant-steps-item-container {
            .ant-steps-item-content {
                .ant-steps-item-title {
                    color: #ffffff;

                    &::after {
                        background-color: #ffffff;
                    }
                }
            }

            .ant-steps-item-tail::after {
                background-color: #ffffff;
            }
        }
    }

    .ant-steps-item-process {
        .ant-steps-item-container {
            .ant-steps-item-icon {
                background: #ffffff;

                .ant-steps-icon {
                    color: var(--antd-wave-shadow-color);
                    font-weight: 600;

                }
            }

            .ant-steps-item-content {
                .ant-steps-item-title {
                    color: #ffffff;

                    &::after {
                        opacity: 0.5;
                    }
                }
            }

            .ant-steps-item-tail::after {
                opacity: 0.5;
            }
        }

        .ant-steps-item-icon {
            border-color: #ffffff;
        }
    }

    .ant-steps-item-wait {
        opacity: 0.5;

        .ant-steps-item-icon {
            .ant-steps-icon {
                color: var(--antd-wave-shadow-color);
            }
        }
        .ant-steps-item-container {
            .ant-steps-item-content {
                .ant-steps-item-title {
                    color: #ffffff;
                }
            }
        }
    }
`
const Sticker = Styled.div`
    padding: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;

    figure {
        margin: 0;
    }

    .qr-description {
        margin-left: 40px;
        min-width: 250px;
        
        h2, h4 {
            text-align: center;
            margin: 0;
            color: #000;
        }

        h4 {
            text-transform: uppercase;
            font-size: 1rem;
        }

        h2 {
            font-weight: 600;
        }

        @media only screen and (max-width: 768px) {
            min-width: 200px;
        }

        @media only screen and (max-width: 525px) {
            min-width: 150px;

            h4 {
                font-size: 0.8rem;
            }

            h2 {
                font-size: 1.3rem;
            }
        }
    }
`
const CardWithHeader = Styled(Card)`
    ${({ border }) =>
      border !== true ? 'border: 1px solid var(--antd-wave-shadow-color) !important' : 'none'};
    
    .ant-card-head {
        background-color: var(--antd-wave-shadow-color);
        color: white;
        box-shadow: 0px 6px 8px -6px rgb(24 39 75 / 12%), 0px 8px 16px -6px rgb(24 39 75 / 8%);
        ${({ border }) => (border !== true ? 'border-radius: none' : '0.625rem 0.625rem 0 0')};

        .ant-card-head-wrapper {
            .ant-card-head-title {
                padding: 16px 0;

                .btn-secondary {
                    color: var(--antd-wave-shadow-color);
                    
                    &:hover {
                        background-color: #FEEFE9;
                    }

                    svg {
                        margin: 0;
                    }
                }
            }
        }

    }

    .ant-card-body {
        .header-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 24px;

            .status {
                padding: 10px;
                border-radius: 10px;

                &.status-warning, &.status-processing, &.status-success {
                    font-weight: 600;
                }

                &.status-warning {
                    border: 1px solid #faad14;

                    .ant-badge-status-text {
                        color: #faad14;
                    }
                }

                &.status-processing {
                    border: 1px solid #1890ff;

                    .ant-badge-status-text {
                        color: #1890ff;
                    }
                }

                &.status-success {
                    border: 1px solid #52c41a;

                    .ant-badge-status-text {
                        color: #52c41a;
                    }
                }

                .ant-badge {
                    margin: 0;
                }
            }
        }

        .form-view {
            .ant-col:nth-child(even)::before {
                margin-right: 6px;
                content: ":";
            }
        }

        .ant-collapse {
            background-color: unset;
            border: none;

            .ant-collapse-item {
                border: none;

                .ant-collapse-header {
                    border-bottom: 1px solid var(--antd-wave-shadow-color);
                    border-radius: unset;
                    color: var(--antd-wave-shadow-color);
                    font-size: 14px;
                    font-weight: 500;

                    svg {
                        fill: var(--antd-wave-shadow-color);
                    }
                }
            }
        }
    }
    
`
const StyleNavigation = Styled.div`
    .nav-profile{
        background-color: #E43A36;
    }
    span, h4{
        color: white !important;
    }
    .ant-menu-item-active:hover{
        background-color: white !important;
        .nav-text{
            color: #E43A36 !important;
        }
        a{
            span{
                color: #E43A36 !important;
            }
        }
    }
`
export {
  StyleIndex,
  StyleForm,
  StyleView,
  StyleCardWrapper,
  StyleSteps,
  Sticker,
  CardWithHeader,
  StyleNavigation,
}
