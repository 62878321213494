import { useCallback } from 'react'
import ApiService from 'services/ApiService'
import { getMessage } from 'utility/MapperResponse'
import { getTranslate } from 'utility/MapperTranslate'
import { showAlert } from 'utility/SweetAlert'

const InteractorWarehouseInventoryHeaderUpdate = () => {
  const warehouseInventoryHeaderUpdate = useCallback(
    (body, loading, redirect = true, path = '', reload = false) => {
      const payload = {
        ...body,
      }
      ApiService.jsonRequest('/warehouse/inventory-header/update', payload, (response) => {
        loading(false)
        if (response.code === 7200) {
          showAlert({
            icon: 'success',
            message: getTranslate('message.successfully_updated'),
            timer: 2000,
            redirect: redirect ? `/warehouse/inventory/manage${path}` : false,
            reload,
          })
        } else {
          showAlert({
            icon: 'error',
            message: getMessage(response?.msg),
            timer: 2000,
          })
        }
      })
    },
    [],
  )
  return { warehouseInventoryHeaderUpdate }
}

export default InteractorWarehouseInventoryHeaderUpdate
