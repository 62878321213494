import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { showAlert } from 'utility/SweetAlert'
import ApiService from 'services/ApiService'
import { removeItem, setItem } from 'utility/Session'
import { getMessage } from 'utility/MapperResponse'
import { FetchAccount } from 'redux/account/creator'
import { getTranslate } from 'utility/MapperTranslate'

const InteractorAccountLogin = () => {
  const dispatch = useDispatch()
  const login = useCallback(
    (payload, loading) => {
      ApiService.jsonRequest('/sso/account/login', payload, (response) => {
        loading(false)
        if (response.code === 7200) {
          // RESET
          removeItem('wdg-account')
          removeItem('wdg-menu')
          removeItem('wdg-notification-unread')
          // END RESET

          if (
            ['DRIVER', 'SALES_STAFF', 'SALES_MANAGER'].includes(
              response.data.account_information?.employee_position_type,
            ) &&
            process.env.REACT_APP_APPLICATION !== 'WEB_CMS'
          ) {
            showAlert({
              icon: 'error',
              message: getTranslate('message.forbidden_access'),
              timer: 2000,
            })
          } else {
            let expires = new Date().getTime() + 8 * 3600 * 1000 // 8 Hours
            const profile = { ...response.data }
            delete profile.created_by
            delete profile.created_date
            delete profile.status
            delete profile.updated_by

            const account = {
              key: 'wdg-account',
              value: profile,
              expires,
            }
            setItem(account)
            dispatch(FetchAccount(profile))
            showAlert({
              icon: 'success',
              message: getTranslate(`message.login_success`),
              timer: 2000,
              reload: true,
            })
          }
        } else {
          showAlert({
            icon: 'error',
            message: getMessage(response?.msg),
            timer: 2000,
          })
        }
      })
    },
    [dispatch],
  )

  return { login }
}

export default InteractorAccountLogin
