import { useCallback } from 'react'
import ApiService from 'services/ApiService'
import { getMessage } from 'utility/MapperResponse'
import { getTranslate } from 'utility/MapperTranslate'
import { showAlert } from 'utility/SweetAlert'

const InteractorFundTransactionCreate = () => {
  const headerCreate = useCallback((body, loading, redirect = 'receipt') => {
    const payload = {
      ...body,
    }
    ApiService.jsonRequest('/transaction/fund-transaction/header-create', payload, (response) => {
      loading(false)
      if (response.code === 7201) {
        let path = `/finance/receipt/view?uid=${response.data.uid}&source=CREATE`
        if (redirect !== 'receipt') {
          path = `${redirect}?uid=${response.data.uid}&source=CREATE`
        }
        showAlert({
          icon: 'success',
          message: getTranslate('message.successfully_created'),
          timer: 2000,
          redirect: path,
        })
      } else {
        showAlert({
          icon: 'error',
          message: getMessage(response?.msg),
          timer: 2000,
        })
      }
    })
  }, [])

  return { headerCreate }
}

export default InteractorFundTransactionCreate
