import { useCallback } from 'react'
import ApiService from 'services/ApiService'
import { getMessage } from 'utility/MapperResponse'
import { getTranslate } from 'utility/MapperTranslate'
import { showAlert } from 'utility/SweetAlert'

const InteractorTransactionOrderUpdate = () => {
  const transactionOrderUpdate = useCallback(
    (body, loading, callback, refreshPage = false, path = '') => {
      const payload = {
        ...body,
      }
      const isAlert = body.alert ?? false
      ApiService.jsonRequest('/transaction/order-header/update', payload, (response) => {
        loading(false)
        if (response.code === 7200) {
          if (isAlert) {
            let timer = 2000
            let msg = getTranslate('message.successfully_updated')
            if (['TOP', 'TOP_CL'].includes(payload.payment_type)) {
              if (response.msg !== '') {
                timer = 3000
                msg = getTranslate(`message.top_${response.msg}`)
              }
            }
            showAlert({
              icon: 'success',
              message:
                payload.is_deleted === 'Y' ? getTranslate('message.successfully_deleted') : msg,
              timer: timer,
              redirect: `/transaction/order/manage${path}`,
            })
          }
          if (refreshPage) {
            showAlert({
              icon: 'success',
              message: getTranslate('message.successfully_created'),
              timer: 2000,
              reload: refreshPage,
            })
          }
          callback(response.data)
        } else {
          showAlert({
            icon: 'error',
            message: getMessage(response?.msg),
            timer: 2000,
          })
        }
      })
    },
    [],
  )
  return { transactionOrderUpdate }
}

export default InteractorTransactionOrderUpdate
