import React from 'react'
import { Row, Col } from 'antd'
import { Aside } from './style'
import Corporate from './Corporate'
import CMS from './CMS'

const AuthLayout = (WraperContent) => {
  return function AuthLayout() {
    return (
      <Aside>
        <Row>
          <Col xl={12} lg={12} md={0} sm={0} xs={0}>
            {process.env.REACT_APP_APPLICATION === 'WEB_CORPORATE' ? <Corporate /> : <CMS />}
          </Col>

          <Col xl={12} lg={12} md={24} xs={24}>
            <WraperContent />
          </Col>
        </Row>
      </Aside>
    )
  }
}

export default AuthLayout
