import React from 'react'
import { SignIn } from './style'

let iconData = [
  { image: require('../../assets/img/auth/icon-1.png'), title: 'Order Management' },
  { image: require('../../assets/img/auth/icon-2.png'), title: 'Pricing Settings' },
  { image: require('../../assets/img/auth/icon-4.png'), title: 'Warehouses & Logistics' },
  { image: require('../../assets/img/auth/icon-3.png'), title: 'Report & Analytics' },
]

const Corporate = () => {
  return (
    <SignIn>
      <div className="auth-side-content">
        <div className="auth-title">
          <h1>Elevate Your Business</h1>
          <h1>with Expert Distribution</h1>
          <h1>Solutions</h1>
        </div>
        <div className="auth-content">
          {iconData.map((item) => (
            <div key={item.title}>
              <img src={item.image} alt={item.title} className="auth-icon" />
              <h2>{item.title}</h2>
            </div>
          ))}
        </div>
      </div>
      <img
        src={require('../../assets/img/auth/login-truck.png')}
        alt="widaga-truck"
        className="auth-illustration"
      />
      <img
        src={require('../../assets/img/auth/left-ornament.png')}
        alt="widaga-truck"
        className="left-ornament"
      />
      <img
        src={require('../../assets/img/auth/right-ornament.png')}
        alt="widaga-truck"
        className="right-ornament"
      />
    </SignIn>
  )
}

export default Corporate
