import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FetchAccount } from 'redux/account/creator'
import ApiService from 'services/ApiService'
import { getMessage } from 'utility/MapperResponse'
import { getTranslate } from 'utility/MapperTranslate'
import { setItem } from 'utility/Session'
import { showAlert } from 'utility/SweetAlert'

const InteractorAccountProfileUpdate = () => {
  const dispatch = useDispatch()
  const { account } = useSelector((state) => state.account)
  const accountUpdate = useCallback(
    (body, loading) => {
      const payload = {
        ...body,
      }
      const isAlert = body.alert ?? false
      delete payload.alert
      ApiService.jsonRequest('/sso/account/update', payload, (response) => {
        loading(false)
        if (response.code === 7200) {
          let expires = new Date().getTime() + 8 * 3600 * 1000 // 8 Hours

          const account_full_name = response.data.account_full_name ?? null
          const account_profile_picture = response.data.account_profile_picture ?? null
          const profile = {
            ...account,
            account_full_name: account_full_name,
            account_profile_picture:
              account_profile_picture === '' ? null : account_profile_picture,
          }
          const data_account = {
            key: 'wdg-account',
            value: profile,
            expires,
          }
          setItem(data_account)
          dispatch(FetchAccount(profile))
          if (isAlert) {
            showAlert({
              icon: 'success',
              message:
                payload.is_deleted === 'Y'
                  ? getTranslate('message.successfully_deleted')
                  : getTranslate('message.successfully_updated'),
              timer: 2000,
              redirect: body.isProfile ? '/' : '/account/profile',
            })
          }
        } else {
          showAlert({
            icon: 'error',
            message: getMessage(response?.msg),
            timer: 2000,
          })
        }
      })
    },
    [account, dispatch],
  )
  return { accountUpdate }
}

export default InteractorAccountProfileUpdate
