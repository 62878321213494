import { findByArray } from './Helpers'
import { getItem } from './Session'

export const getMenu = (menu) => {
  let submenu = []
  menu.forEach((row) => {
    let main_submenu = []
    if (row.children) {
      row.children.forEach((sub) => {
        const children = {
          key: sub.uid,
          path: sub.menu_path,
          title: sub.menu_name,
          icon: sub.menu_icon,
          breadcrumb: false,
          submenu: [],
        }
        main_submenu.push(children)
      })
    }
    let main = {
      key: row.uid,
      path: row.menu_path,
      title: row.menu_name,
      icon: row.menu_icon,
      breadcrumb: false,
      submenu: main_submenu,
    }
    submenu.push(main)
  })
  let data = [
    {
      key: 'MENU',
      path: '/',
      title: 'MENU',
      icon: null,
      breadcrumb: false,
      submenu: submenu,
    },
  ]
  return data
}

export const verifyAccessMenu = (path) => {
  const access = getItem('wdg-access')
    ? findByArray(getItem('wdg-access'), 'menu_path', path)
    : null
  return access
}

export const verifyAccessGroup = (path, options = null) => {
  let access = []
  getItem('wdg-access').forEach((element) => {
    if (element?.menu_path.indexOf(path + '/') > -1) {
      access.push(element)
    }
  })

  if (access.length > 0) {
    if (options) {
      const result = {}
      Object.entries(options).forEach(([key, value]) => {
        result[key] = findByArray(access, 'menu_path', path + value)
      })
      return result
    }
    return access
  }

  return null
}
