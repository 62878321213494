import { useCallback } from 'react'
import ApiService from 'services/ApiService'
import { getMessage } from 'utility/MapperResponse'
import { getTranslate } from 'utility/MapperTranslate'
import { showAlert } from 'utility/SweetAlert'

const InteractorTransactionOrderUpdateBill = () => {
  const transactionOrderUpdateBill = useCallback((body, loading, callback, refreshPage = false) => {
    const payload = {
      ...body,
    }
    const isAlert = body.alert ?? false
    ApiService.jsonRequest('/transaction/order-header/update-bill', payload, (response) => {
      loading(false)
      if (response.code === 7200) {
        if (isAlert) {
          let timer = 2000
          let msg = getTranslate('message.successfully_updated')
          showAlert({
            icon: 'success',
            message:
              payload.is_deleted === 'Y' ? getTranslate('message.successfully_deleted') : msg,
            timer: timer,
            redirect: '/transaction/order/manage',
          })
        }
        if (refreshPage) {
          showAlert({
            icon: 'success',
            message: getTranslate('message.successfully_created'),
            timer: 2000,
            reload: refreshPage,
          })
        }
        callback(response.data)
      } else {
        showAlert({
          icon: 'error',
          message: getMessage(response?.msg),
          timer: 2000,
        })
      }
    })
  }, [])
  return { transactionOrderUpdateBill }
}

export default InteractorTransactionOrderUpdateBill
