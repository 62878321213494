import { Row } from 'antd'
import Styled from 'styled-components'

const DatePickerWrapper = Styled.div`
    .ant-picker{
        &:hover {
            border-color: var(--antd-wave-shadow-color);
        }
        min-width: 0px;
        border-color: #E3E6EF;
        border-radius: 5px;
        &.ant-picker-focused {
            border-color: var(--antd-wave-shadow-color);
            box-shadow: 0 0 0 2px rgb(242 93 39 / 20%);
            .ant-picker-separator {
                color: var(--antd-wave-shadow-color);
            }
        }
        .ant-picker-clear:hover {
            color: #ff6b72;
        }
    }
    .ant-picker:not(:last-child){
        margin-bottom: 20px;
    }
    .ant-picker-input > input{
        color: #5A5F7D;
    }
    .ant-picker-range .ant-picker-input > input{
        text-align: center;
        font-weight: 500;
    }
`

const TextWrapper = Styled.div`
    .ant-input-affix-wrapper-focused, .ant-input-affix-wrapper:focus {
        border-color: #E43A36;
        box-shadow: rgba(274, 100, 62 , 0.2) 0px 0px 0px 2px;
    }
    .ant-input-affix-wrapper {
        &:hover{
            border-color: var(--antd-wave-shadow-color);
        }
    }
`

const SelectWrapper = Styled.div`
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        box-shadow: rgba(274, 100, 62 , 0.2) 0px 0px 0px 2px;
        border: 1px solid #E43A36;
    }
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        border-color: #E43A36;
    }
    .ant-select-single{
      .ant-select-selector{
          padding: 0 20px;
          font-size: 14px;
          height: 40px !important;
          .ant-select-selection-item{
              line-height: 40px !important;
              padding: 0 !important;
          }
          .ant-select-selection-placeholder{
              line-height: 40px !important;
          }
      }
    }
    .ant-select-clear:hover {
        color: #ff6b72;
    }
`

const InputWrapper = Styled.div`
    .ant-input{
        padding: 0 20px 0px 5px !important;
        font-size: 14px;
        height: 40px !important;
        border: 1px solid ${({ theme }) => theme['border-color-normal']};
    }
`

const PopUpVideoWrapper = Styled.div`
    position: relative;
    display: inline-flex;
    .video-mask-preview {
        position: absolute;
        display: flex;
        height: 100%;
        width: 100%;
        top: 0;
        background: rgba(0, 0, 0, 0.5);
        opacity: 0;
        align-items: center;
        justify-content: center;
        color: #fff;
        transition: all ease-in-out 0.3s;
        font-weight : 400;
        svg {
            margin-right: 5px
        }
        &:hover{
            opacity: 1;
        }
    }
    .ant-modal-content {
      background: transparent
    }

    .ant-modal-body .ant-input-number {
        width: 100%;
    }
`

const DragDropWrapper = Styled(Row)`
    height: 150px;
    width: 100%;
    .ant-upload{
        position: relative;
        height: 100%;
        input {
            position: absolute;
            height: 100%;
            width: 100%;
            opacity: 0;
        }
        .button-face{
            height: 100%;
            width: 80%;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 30px;
            text-align: start;
            svg{
                font-size: 2rem;
            }
        }
    }
`
export {
  TextWrapper,
  InputWrapper,
  SelectWrapper,
  DatePickerWrapper,
  PopUpVideoWrapper,
  DragDropWrapper,
}
